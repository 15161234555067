import PropTypes from "prop-types"

import { GatsbyImageSourceProps } from "./ImageProps"

const ListItemProps = {
  title: PropTypes.string,
  link: PropTypes.string,
}

const ContentItemProps = {
  title: PropTypes.string,
  link: PropTypes.strtig,
  icon: PropTypes.string,
}

export const NavigationProps = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      image: PropTypes.shape(GatsbyImageSourceProps),
      items: PropTypes.arrayOf(PropTypes.shape(ListItemProps)),
      contents: PropTypes.arrayOf(PropTypes.shape(ContentItemProps)),
    }),
  ),
}
