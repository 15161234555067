import { useCallback } from "react"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"
import { useShop } from "./useShop"
import { useApp } from "./useApp"
import { useCustomerLogout } from "./useCustomer"

export const useCheckout = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: {
        CHECKOUT_CREATE,
        CHECKOUT_ATTRIBUTES_UPDATE,
        CHECKOUT_SHIPPING_ADDRESS_UPDATE,
        CHECKOUT_EMAIL_UPDATE,
        CHECKOUT_DISCOUNT_APPLY,
        CHECKOUT_GIFTCARDS_APPEND,
        CHECKOUT_CUSTOMER_ASSOCIATE,
      },
      queries: { GET_CHECKOUT, GET_SHIPPING_RATES, GET_PRODUCTS_BY_HANDLE },
    },
  } = useCore()
  const {
    shop,
    checkout,
    setCheckout: saveCheckout,
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useMutation, useQuery, client } = useShopify()
  const { logoutCustomer } = useCustomerLogout()
  const { getShop } = useShop()
  const checkoutId = storage.get(keys?.checkout)

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)
  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE)
  const [checkoutShippingAddressUpdate] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE)
  const [checkoutEmailUpdate] = useMutation(CHECKOUT_EMAIL_UPDATE)
  const [checkoutDiscountApply] = useMutation(CHECKOUT_DISCOUNT_APPLY)
  const [checkoutGiftcardAppend] = useMutation(CHECKOUT_GIFTCARDS_APPEND)

  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const { refetch: getShippingRatesQuery } = useQuery(GET_SHIPPING_RATES, { fetchPolicy: "no-cache", skip: true })

  const getCheckout = useCallback(async () => {
    try {
      if (checkoutId) {
        const {
          data: { node: checkout },
        } = await getCheckoutQuery({ checkoutId })

        return checkout
      }
      return false
    } catch (error) {
      console.error(error)
    }
  }, [checkoutId, getCheckoutQuery])

  const setCheckout = useCallback(
    checkout => {
      try {
        saveCheckout({
          ...checkout,
          lineItems:
            checkout?.lineItems?.edges.map(({ node }) => ({
              ...node,
              variant: {
                ...node?.variant,
                priceV2: Object.assign(
                  {},
                  ...node?.variant?.presentmentPrices?.edges
                    ?.filter(({ node }) => node?.price?.currencyCode === checkout?.currencyCode)
                    .map(({ node }) => node?.price)
                ),
                compareAtPriceV2: Object.assign(
                  {},
                  ...node?.variant?.presentmentPrices?.edges
                    ?.filter(({ node }) => node?.compareAtPrice?.currencyCode === checkout?.currencyCode)
                    .map(({ node }) => node?.compareAtPrice)
                ),
              },
            })) || [],
        })
        storage.set(keys?.checkout, checkout?.id)
      } catch (error) {
        console.error(error)
      }
    },
    [saveCheckout]
  )

  const createCheckout = useCallback(
    async (presentmentCurrencyCode: string = "AUD", forceNew: boolean = false) => {
      try {
        const existingCheckout = !forceNew && (await getCheckout())

        if (forceNew || !existingCheckout?.id || existingCheckout?.completedAt !== null || Object.keys(existingCheckout).length < 1) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: { input: { presentmentCurrencyCode } },
          })
          if (checkout) setCheckout(checkout)
        } else {
          setCheckout(existingCheckout)
        }
        getShop()
      } catch (error) {
        storage.remove(keys?.checkout)
        console.error(error)
      }
    },
    [getCheckout, setCheckout, checkoutCreate, getShop]
  )

  const updateAttributes = useCallback(
    async input => {
      const {
        data: { checkoutAttributesUpdateV2: data },
      } = await checkoutAttributeUpdate({
        variables: { checkoutId, input },
      })
      setCheckout(data?.checkout)
    },
    [checkoutAttributeUpdate, setCheckout]
  )

  const updateCustomer = useCallback(
    async customerAccessToken => {
      const {
        data: { checkoutCustomerAssociateV2: data },
      } = await checkoutCustomerAssociate({
        variables: { checkoutId, customerAccessToken },
      })
      setCheckout(data?.checkout)
    },
    [checkoutCustomerAssociate, setCheckout]
  )

  const updateShippingAddress = useCallback(
    async input => {
      const {
        data: { checkoutShippingAddressUpdateV2: data },
      } = await checkoutShippingAddressUpdate({
        variables: {
          checkoutId,
          shippingAddress: {
            firstName: input?.firstName,
            lastName: input?.lastName,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            country: input?.country,
            phone: input?.phone,
            province: input?.province,
            zip: input?.zip,
          },
        },
      })
      setCheckout(data?.checkout)
    },
    [checkoutShippingAddressUpdate, setCheckout]
  )

  const updateEmail = useCallback(
    async email => {
      const {
        data: { checkoutEmailUpdateV2: data },
      } = await checkoutEmailUpdate({
        variables: { checkoutId, email },
      })
      setCheckout(data?.checkout)
    },
    [checkoutEmailUpdate, setCheckout]
  )

  const applyDiscountCode = useCallback(
    async discountCode => {
      const {
        data: { checkoutDiscountCodeApplyV2: data },
      } = await checkoutDiscountApply({
        variables: { checkoutId, discountCode },
      })

      if (!data.checkoutUserErrors.length) {
        storage.set(
          keys?.discounts,
          [...(storage.get(keys?.discounts) || []), discountCode].filter((value, index, self) => self.indexOf(value) === index)
        )
        setCheckout(data?.checkout)
      } else {
        return data
      }
    },
    [checkoutDiscountApply, setCheckout]
  )

  const applyGiftCardCode = useCallback(
    async giftCardCode => {
      const {
        data: { checkoutGiftCardsAppend: data },
      } = await checkoutGiftcardAppend({
        variables: { checkoutId, giftCardCodes: [giftCardCode] },
      })

      if (!data.checkoutUserErrors.length) {
        setCheckout(data.checkout)
        return this
      } else {
        return data
      }
    },
    [checkoutGiftcardAppend, setCheckout]
  )

  const getShippingRates = useCallback(async () => {
    const {
      data: { node: checkout },
    } = await getShippingRatesQuery({ id: checkoutId })

    setCheckout(checkout)
    return checkout
  }, [getShippingRatesQuery, setCheckout])

  const applyDiscounts = useCallback(async () => {
    const discountCodes = storage.get(keys?.discounts) || []
    for (const discountCode of discountCodes) {
      await applyDiscountCode(discountCode)
    }
  }, [applyDiscountCode])

  const duplicateCheckout = useCallback(
    async (changes = {}) => {
      const { currencyCode, customAttributes, lineItems, note, email, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      const {
        data: { checkoutCreate: data },
      } = await checkoutCreate({
        variables: {
          input: {
            ...(email && { email }),
            ...(note && { note }),
            ...(customAttributes && { customAttributes }),
            presentmentCurrencyCode: currencyCode,
            lineItems:
              lineItems?.map(item => ({
                variantId: item?.variant.id,
                quantity: item?.quantity || 1,
                customAttributes:
                  item?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              })) || [],
            shippingAddress: shippingAddress
              ? {
                  address1: shippingAddress.address1,
                  address2: shippingAddress.address2,
                  city: shippingAddress.city,
                  company: shippingAddress.company,
                  country: shippingAddress.country,
                  firstName: shippingAddress.firstName,
                  lastName: shippingAddress.lastName,
                  phone: shippingAddress.phone,
                  province: shippingAddress.province,
                  zip: shippingAddress.zip,
                }
              : undefined,
            ...changes,
          },
        },
      })
      if (!discounts.length) setCheckout(data?.checkout)
      if (discounts.length) applyDiscounts()
    },
    [checkout, checkoutCreate, setCheckout, applyDiscounts]
  )

  const migrateCheckout = useCallback(
    async (changes = {}) => {
      const { lineItems, note, email, customAttributes, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      if (lineItems?.length) {
        const mappedLineItems =
          lineItems?.map(item => ({
            handle: item?.variant?.product?.handle,
            sku: item?.variant?.sku,
            quantity: item?.quantity,
            customAttributes: item?.customAttributes,
          })) || []

        const { data: matchedProducts } = await client.query({
          query: GET_PRODUCTS_BY_HANDLE(mappedLineItems.map(product => product?.handle)),
          variables: {
            firstCollections: 0,
            firstImages: 0,
            firstMetafields: 0,
            firstVariants: 100,
          },
        })

        const migratedLineItems =
          mappedLineItems.map(lineItem => ({
            variantId: matchedProducts[lineItem.handle.replace(/-/g, "")]?.variants?.edges
              ?.filter(({ node }) => node?.sku === lineItem?.sku)
              .map(({ node }) => node?.id)[0],
            quantity: lineItem?.quantity,
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
          })) || []

        const {
          data: { checkoutCreate: data },
        } = await checkoutCreate({
          variables: {
            input: {
              ...(email && { email }),
              ...(note && { note }),
              ...(customAttributes && { customAttributes }),
              presentmentCurrencyCode: shop?.currencyCode,
              lineItems: migratedLineItems,
              shippingAddress: shippingAddress || undefined,
              ...changes,
            },
          },
        })
        if (!discounts.length) setCheckout(data?.checkout)
        if (discounts.length) applyDiscounts()
        getShop()
        logoutCustomer()
      } else {
        createCheckout(shop?.currencyCode, true)
      }
    },
    [checkout, createCheckout, checkoutCreate, setCheckout, applyDiscounts, logoutCustomer, getShop, client, GET_PRODUCTS_BY_HANDLE]
  )

  const updateCurrency = useCallback(
    async presentmentCurrencyCode => {
      await duplicateCheckout({
        presentmentCurrencyCode: presentmentCurrencyCode,
      })
      getShop()
    },
    [duplicateCheckout, getShop]
  )

  const cartItemsQuantity = checkout?.lineItems?.reduce((acc, cur) => acc + cur.quantity, 0)

  return {
    checkout,
    createCheckout,
    getCheckout,
    setCheckout,
    migrateCheckout,
    updateCurrency,
    updateAttributes,
    updateShippingAddress,
    updateEmail,
    updateCustomer,
    applyDiscountCode,
    applyGiftCardCode,
    getShippingRates,
    cartItemsQuantity,
  }
}
