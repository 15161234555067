import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import Icon from "../Icon"

const StyledLink = styled.div`
  ${tw`relative mb-1-6 w-1/4 flex flex-col items-center justify-center hover:text-orange`}
`

const Title = styled.p`
  ${tw`absolute bottom-0 block text-14 leading-1.29 text-center transition-all duration`}
`

const StyledIcon = styled(Icon)`
  ${tw`transition-all duration`}
`

const NavigationHeaderSubShoeIcon = ({ title, url, icon, className }) => {
  return (
    <StyledLink as={url ? Link : `div`} className={className} to={url}>
      <StyledIcon name={icon} size={`xxlarge`} />
      <Title>{title}</Title>
    </StyledLink>
  )
}

NavigationHeaderSubShoeIcon.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
}

export default NavigationHeaderSubShoeIcon
