import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withPrice } from "./withPrice"

const Wrapper = styled.div`
  ${({ layout }) => {
    if (layout === `product`) return tw`relative`
    if (layout === `cart`) return tw`flex flex-row`
    else return tw`flex flex-col justify-end`
  }}
`

const Money = styled.span`
  ${({ sale }) => (sale ? tw`text-orange font-medium ` : null)}
  ${({ layout }) => {
    if (layout === `product`) return tw`absolute top-2-4 right-0`
    if (layout === `cart`) return tw`ml-0-4`
    else return tw`mt-0-2`
  }}
`

const Price = withPrice(({ onSale, currencyCode, price, comparedPrice, className, layout }) => {
  return (
    <div className={className}>
      {onSale ? (
        <Wrapper layout={layout}>
          <strike>
            {currencyCode}
            {comparedPrice}
          </strike>
          <Money sale={onSale} layout={layout}>
            {currencyCode}
            {price}
          </Money>
        </Wrapper>
      ) : (
        <Money>
          {currencyCode}
          {price}
        </Money>
      )}
    </div>
  )
})

export default Price
