import styled from "@emotion/styled"
import tw from "twin.macro"

export const styles = {
  H1: tw`font-display text-38 sm:text-56 leading-1.21 leading-1.36`,
  H2: tw`font-display text-28 md:text-44 leading-1.29 md:leading-1.45 text-center md:text-left`,
  H3: tw`font-display text-20 leading-1.5 md:text-32 md:leading-1.75 text-center md:text-left`,
  H4: tw`font-body text-20 leading-1.4 font-medium text-center md:text-left`,
  H5: tw`font-body text-14 leading-2.29 tracking-relaxed uppercase`,
  P: tw`font-body text-16 leading-1.75 text-center md:text-left`,
  Small: tw`font-body text-14 leading-1.71`,
  XSmall: tw`font-body text-12 leading-2`,
}

export const StyledH1 = styled.h1`
  ${styles.H1}
`
export const StyledH2 = styled.h2`
  ${styles.H2}
`
export const StyledH3 = styled.h3`
  ${styles.H3}
`
export const StyledH4 = styled.h4`
  ${styles.H4}
`
export const StyledH5 = styled.h5`
  ${styles.H5}
`

export const StyledP = styled.p`
  ${styles.P}
`
export const StyledSmall = styled.p`
  ${styles.Small}
`

export const StyledXSmall = styled.p`
  ${styles.XSmall}
`
