module.exports = {
  app: {
    title: "Bared Footwear",
    description:
      "Look good, feel better. Designed by a podiatrist, Bared Footwear offers seriously stylish shoes for sneaker comfort, everyday. Unbeatable customer service. Free delivery. Free returns.",
    url: "https://staging.bared.dotdev.cloud",
    logo: "static/images/logo.svg",
    themeColor: "#000000",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    themeIcon: "static/favicon.png",
    // for buildManifestConfig
  },
  stores: {
    "bared-footwear-au": {
      siteLocation: "AU",
      siteCountries: "AU",
      shopName: "bared-footwear-au",
      accessToken: "7189daea826cfc8908ded33539abcf1b",
      sanityDataset: "production",
      searchIndex: "reactify-search",
    },
  },
  services: {
    shopify: {
      apiVersion: "2020-07",
      paginationSize: "100",
      defaultShopName: "bared-footwear-au",
    },
    sanity: {
      projectId: "z60zyrm2",
      token:
        "skQ9NPtTzmzZD3baRFNTXDOGVgMh3gnj7wYiS6rQSaapCxmERzkF8Yo2Fnfgd2GTMMKUVgIGAgJ4Qh2zbWnTgqQSeywMDEGeEXX7IZ9YupBFRpGZCR9VwoCoZHH5W9zchMcbFo2MoyyJssKyjynhkEwydcK2A4YW03VVAkFZkCTgtEc1V217",
    },
    firebase: {
      region: "us-central1",
      apiKey: "AIzaSyCXT9Rsvr_kNvhhIqPpAeG-EGLLci8iGy8",
      authDomain: "bared-footwear-website.firebaseapp.com",
      databaseURL: "https://bared-footwear-website.firebaseio.com",
      projectId: "bared-footwear-website",
      storageBucket: "bared-footwear-website.appspot.com",
      messagingSenderId: "37454649381",
      appId: "1:37454649381:web:fc1907e0d14d1516e787cc",
    },
    location: {
      serviceUrl: "https://ipapi.co/json/",
      countryFieldKey: "country",
      forceRegion: true,
    },
    reactify: {
      searchUrl: "https://storefront:password@1156854deeb245138b225a0c630fade2.australia-southeast1.gcp.elastic-cloud.com/",
      searchConfig: "https://search.reactify.app/api/config",
      searchDocument: "product",
      searchFieldKey: "q",
    },
    forms: {
      notify: "/klaviyo/back-in-stock", //TODO update when klaviyo setup
    },
  },
  queries: {
    products: {
      query: `allShopifyProduct { edges { node { handle tags } } }`,
      template: "templates/product.tsx",
      hidden: "hidden",
      parentTagPrefix: "parent",
      siblingTagPrefix: "colours",
      devPageLimit: 3,
    },
    collections: {
      query: `allSanityCollection { edges { node { shopify {  handle: shopifyHandle } parent: parentCollection { shopify { handle: shopifyHandle } parent: parentCollection {  shopify { handle: shopifyHandle } } }  } } }`,
      template: "templates/collection.tsx",
      hidden: "hidden",
      devPageLimit: 3,
    },
    articles: {
      query: `allSanityArticle { edges { node { _id slug { current } } } }`,
      template: "templates/article.tsx",
      devPageLimit: 3,
    },
    stores: {
      query: `allSanityStore { edges { node { _id slug { current } } } }`,
      template: "templates/store.tsx",
      devPageLimit: 3,
    },
    flexible: {
      query: `allSanityPageFlexible { edges { node { _id slug { current } } } }`,
      template: "templates/flexible.tsx",
    },
    generic: {
      query: `allSanityPageGeneric { edges { node { _id slug { current } } } }`,
      template: "templates/generic.tsx",
    },
    redirects: {
      query: `allSanityRedirect { edges { node { source type destination } } }`,
      devPageLimit: 0,
    },
    robots: {
      query: `allSanitySettingRobots { nodes { content { code } } }`,
    },
  },
  settings: {
    search: {
      filters: [
        { id: "type", title: "Type", field: "product_type.keyword", type: "single", view: "list", config: { size: 8, react: {} } },
        { id: "vendor", title: "Vendor", field: "vendor.keyword", type: "multi", view: "check", config: { size: 5 } },
        { id: "brand", title: "Brand", field: "tags_brand.keyword", type: "multi", view: "check", config: { size: 5 } },
        { id: "colourTag", title: "Colour (Tag)", field: "tags_swatch.keyword", type: "multi", view: "swatch", config: { size: 48 } },
        { id: "colour", title: "Colour", field: "variants.option1.keyword", type: "multi", view: "swatch", config: { size: 48 } },
        { id: "size", title: "Size", field: "variants.option2.keyword", type: "multi", view: "box", config: { size: 12 } },
        { id: "price", title: "Price", field: "price_min", type: "range" },
      ],
      sort: [
        { label: "Price lowest", dataField: "price_min", sortBy: "asc" },
        { label: "Price highest", dataField: "price_max", sortBy: "desc" },
        { label: "Title a-Z", dataField: "title.keyword", sortBy: "asc" },
        { label: "Title z-A", dataField: "title.keyword", sortBy: "desc" },
      ],
      fields: ["title", "vendor", "product_type", "tags", "handle"],
      weights: [10, 3, 5, 6, 8],
      fieldKey: "q",
    },
    keys: {
      shopify: "headless:shopify",
      country: "headless:country",
      location: "headless:location",
      checkout: "headless:checkout",
      discounts: "headless:discounts",
      customer: "headless:customer",
      wishlist: "headless:wishlist",
      announcement: "headless:announcement",
      maintenance: "headless:maintenance",
      password: "headless:password",
      subscribe: "headless:subscribe",
    },
    routes: {
      PAGE: "",
      HOMEPAGE: "/",
      FLEXIBLE: "/",
      GENERIC: "/",
      CART: "/cart",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/password",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      WISHLIST: "/account/wishlist",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      ARTICLES: "/articles",
      ARTICLE: "/articles",
      STORES: "/stores",
      STORE: "/stores",
    },
    defaultRedirects: [
      { source: "/collection", destination: "/collections", type: 301 },
      { source: "/checkout", destination: "/cart", type: 301 },
      { source: "/home", destination: "/", type: 301 },
    ],
    clientPaths: ["/account/reset/*", "/account/activate/*", "/account/orders/*"],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/account/wishlist",
      "/account/saved",
      "/search",
      "/saved",
      "/saved/share",
      "/cart",
      "/cart/login",
    ],
    socialMedia: {
      sharing: {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=",
        pinterest: "http://pinterest.com/pin/create/button/?url=",
      },
    },
  },
}
