/**
 * Adds a Shopify size attribute to a URL
 *
 * @param src
 * @param size
 * @returns {*}
 */
export const imageUrl = (src, size) => {
  if (!src || typeof src != "string") return null
  if (typeof src === `string` && !src.includes(`shopify.com`)) return src

  if (typeof size === "number") {
    size = `${size}x${size}`
  }

  const match = src.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i)
  let prefix,
    suffix = null

  if (match) {
    prefix = src.split(match[0])
    suffix = match[0]
  } else {
    return null
  }

  return !size || size === "master" || !src.match(/cdn/i)
    ? removeProtocol(src)
    : removeProtocol(`${prefix[0]}_${size}${suffix}`)
}

/**
 * Removes any url protocols from the provided path
 *
 * @param path
 * @returns {*}
 */
export const removeProtocol = path => {
  return path.replace(/http(s)?:/, "")
}
