import React, { useState } from "react"

import { useFunctions } from "../../hooks/useFunctions"
import { useSettings } from "../../hooks/useSettings"
import { useCore } from "../../hooks/useCore"
import { useApp } from "../../hooks/useApp"
import { useEffect } from "react"

export const withSubscribePopup = Component => ({ name = "Subscribe" }) => {
  const {
    helpers: { sanityContent, storage },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
    activeSubscribe,
    setActiveSubscribe,
  } = useApp()
  const { subscribePopup } = useSettings()
  const { customerSubscribe, errors } = useFunctions()
  const [data, setData] = useState({ email: "" })
  const [tags, setTags] = useState([])
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setActiveSubscribe(storage.get(keys?.subscribe) !== "hidden")
  })

  const handleSubmit = async event => {
    event.preventDefault()
    const { customer } = await customerSubscribe(data?.email, tags)
    if (customer?.id) {
      setSuccess(true)
    }
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const toogleTag = tag => {
    const index = tags.indexOf(tag)
    index === -1 ? setTags([...tags, tag]) : setTags(tags.filter(a => a !== tag))
  }

  const options = subscribePopup?.options
  const title = subscribePopup?.popupTitle
  const description = sanityContent(subscribePopup?.popupContent)
  const successTitle = subscribePopup?.popupSuccessTitle
  const successMessage = sanityContent(subscribePopup?.popupSuccessMessage)
  const terms = sanityContent(subscribePopup?.popupTerms)
  const image = subscribePopup?.image?.asset?.fluid

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      errors={errors}
      options={options}
      title={title}
      description={description}
      successTitle={successTitle}
      successMessage={successMessage}
      terms={terms}
      image={image}
      tags={tags}
      toogleTag={toogleTag}
      success={success}
      active={activeSubscribe}
      setActive={setActiveSubscribe}
    />
  )
}
