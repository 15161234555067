import React from "react"
import PropTypes from "prop-types"

import { formatCurrency } from "../utils/formatCurrency"

const Money = ({ price = {}, className }) => {
  const amount = price?.amount
  const currencyCode = price?.currencyCode

  const formated = formatCurrency(Number(amount), currencyCode, 2)
  return (
    <p className={className}>
      {currencyCode}
      {formated}
    </p>
  )
}

Money.propTypes = {
  price: PropTypes.shape({
    amount: PropTypes.string,
    currencyCode: PropTypes.string,
    local: PropTypes.string,
  }),
}

export default Money
