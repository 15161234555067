import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

const styles = {
  width: {
    full: tw`max-w-full`,
    xl: tw`max-w-xl mx-auto px-2-4 sm:px-3-2 md:px-7-2`,
    lg: tw`max-w-120 mx-auto px-2-4 sm:px-3-2 md:px-7-2`,
    md: tw`max-w-100 mx-auto px-2-4 sm:px-3-2 md:px-7-2`,
    sm: tw`max-w-70 mx-auto px-2-4 sm:px-3-2`,
  },
  background: {
    green: tw`bg-green text-lightest`,
    light: tw`bg-light`,
    lighter: tw`bg-lighter`,
    lightest: tw`bg-lightest`,
    transparent: tw`bg-transparent`,
  },
}

export const BackgroundContainer = styled.section`
  ${tw`w-full`}
  ${({ background }) => (background ? styles.background[background] : null)}
`

export const StyledContainer = styled.div`
  ${tw`w-full`}
  ${({ width }) => (width ? styles.width[width] : null)}
`

StyledContainer.propTypes = {
  width: PropTypes.oneOf(Object.keys(styles.width)),
}

StyledContainer.defaultProps = {
  width: "xl",
}

BackgroundContainer.propTypes = {
  background: PropTypes.oneOf(Object.keys(styles.background)),
}

BackgroundContainer.defaultProps = {
  background: "",
}
