import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import { withCartLineitemCard } from "./withCartLineitemCard"
import Image from "../Image/Image"
import Icon from "../Icon"
import Price from "../Price/Price"
import FormDropdown from "../Form/FormDropdown"
import { LineitemProps } from "../../props/LineitemProps"

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full bg-lightest relative`}
  ${({ layout }) => (layout === `cart` ? tw`p-2-4` : tw`py-3-2 px-2-4 md:p-1-6`)}
`

const Close = styled(Icon)`
  ${tw`absolute cursor-pointer`}
  ${({ layout }) => (layout === `cart` ? tw`top-1-2 md:top-2-4 right-1-2 md:right-2-4` : tw`top-1-2 right-1-2 md:top-1-6 md:right-2-4`)}
`

const Desktop = tw.div`
  hidden md:flex flex-row justify-between items-center
`

const Mobile = tw.div`
  md:hidden w-full
`

const ImageWrapper = styled.div`
  ${tw`mr-2-4`}
  ${({ layout }) => (layout === "cart" ? tw`w-10 md:w-14-4` : tw`w-10`)}
`

const InfosWrapper = tw.div`
  flex-1 flex flex-col
`

const Title = styled.span`
  ${tw`inline`}
  ${({ layout }) => (layout === "cart" ? tw`` : tw`md:pr-4 md:mb-1-6`)}
`

const Text = styled.p`
  ${tw`inline capitalize leading-1.67 text-14 md:text-16`}
  ${({ bold }) => (bold ? tw`font-medium` : null)}
`

const StyledPrice = styled(Price)`
  ${({ layout }) => (layout === "cart" ? tw`mt-0-4 mb-2-4` : tw`leading-1.67`)}
  ${tw`text-14 md:text-16`}
`

const Selectors = tw.div`
  w-full md:w-auto flex flex-row
`

const SizeSelector = styled(FormDropdown)`
  ${tw`w-1/2 md:w-10 text-14 mr-0-4 h-3-2 cursor-pointer`}
`

const QuantitySelector = styled.div`
  ${({ layout }) => (layout === "cart" ? tw`ml-0-4 md:ml-1 w-1/2 md:w-10 h-3-3` : tw`w-13 md:w-8 h-3-2 md:h-2-4 mt-1-6 md:mt-0`)}
  ${tw`border border-dark px-1 flex items-center justify-between`}
`

const QuantityAmount = tw.p`
  text-center text-14 mb-0-2
`

const QuantityButton = tw.button`
  focus:outline-none
`

const CartLineitemCard = withCartLineitemCard(
  ({
    lineitem: { id, link, image, title, subTitle, quantity, price, compareAtPrice, selectedOptions },
    className,
    layout,
    options,
    handleSelect,
    removeFromCart,
    updateQuantity,
    cartLoading,
  }) => {
    return (
      <Wrapper className={className} layout={layout}>
        <Close name={`close`} size={`xsmall`} layout={layout} onClick={() => removeFromCart(id)} />
        <ImageWrapper>
          <Image image={image} ratio={`1/1`} onClick={() => navigate(link)} />
        </ImageWrapper>
        <InfosWrapper>
          <Title layout={layout}>
            <Text bold>{title}</Text>
            <Text>&nbsp;{subTitle}</Text>
          </Title>
          {layout === "cart" && <StyledPrice price={price} compareAtPrice={compareAtPrice} layout={layout} />}
          {layout === "drawer" && (
            <Mobile>
              <Text>
                {selectedOptions.name}: {selectedOptions.value}
              </Text>
              <StyledPrice price={price} compareAtPrice={compareAtPrice} layout={layout} />
              <QuantitySelector layout={layout}>
                <QuantityButton disabled={cartLoading || quantity <= 1} onClick={() => updateQuantity(id, quantity - 1)}>
                  <Icon name={`minusSmall`} size={`none`} height={`16`} width={`8`} />
                </QuantityButton>
                <QuantityAmount>{quantity}</QuantityAmount>
                <QuantityButton disabled={cartLoading} onClick={() => updateQuantity(id, quantity + 1)}>
                  <Icon name={`plusSmall`} size={`none`} height={`16`} width={`8`} />
                </QuantityButton>
              </QuantitySelector>
            </Mobile>
          )}
          <Desktop>
            <Selectors>
              {layout === "cart" && (
                <SizeSelector
                  name={`selector`}
                  options={options}
                  value={selectedOptions.value}
                  handleChange={value => handleSelect({ target: { value } })}
                  layout={`cart`}
                />
              )}
              <QuantitySelector layout={layout}>
                <QuantityButton disabled={cartLoading || quantity <= 1} onClick={() => updateQuantity(id, quantity - 1)}>
                  <Icon name={`minusSmall`} size={`none`} height={`16`} width={`8`} />
                </QuantityButton>
                <QuantityAmount>{quantity}</QuantityAmount>
                <QuantityButton disabled={cartLoading} onClick={() => updateQuantity(id, quantity + 1)}>
                  <Icon name={`plusSmall`} size={`none`} height={`16`} width={`8`} />
                </QuantityButton>
              </QuantitySelector>
            </Selectors>
            {layout === "drawer" && (
              <>
                <Text>
                  {selectedOptions.name}: {selectedOptions.value}
                </Text>
                <StyledPrice price={price} compareAtPrice={compareAtPrice} />
              </>
            )}
          </Desktop>
        </InfosWrapper>
        {layout === "cart" && (
          <Mobile>
            <Selectors>
              <SizeSelector
                name={`selector`}
                options={options}
                value={selectedOptions.value}
                handleChange={value => handleSelect({ target: { value } })}
                layout={`cart`}
              />

              <QuantitySelector layout={layout}>
                <QuantityButton disabled={cartLoading || quantity <= 1} onClick={() => updateQuantity(id, quantity - 1)}>
                  <Icon name={`minusSmall`} size={`none`} height={`16`} width={`8`} />
                </QuantityButton>
                <QuantityAmount>{quantity}</QuantityAmount>
                <QuantityButton disabled={cartLoading} onClick={() => updateQuantity(id, quantity + 1)}>
                  <Icon name={`plusSmall`} size={`none`} height={`16`} width={`8`} />
                </QuantityButton>
              </QuantitySelector>
            </Selectors>
          </Mobile>
        )}
      </Wrapper>
    )
  }
)

CartLineitemCard.propTypes = {
  className: PropTypes.string,
  lineitem: PropTypes.shape(LineitemProps),
}

export default CartLineitemCard
