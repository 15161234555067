import React from "react"
import PropTypes from "prop-types"
import Select, { components } from "react-select"

import Icon from "../Icon"

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon name={`arrowDropdown`} size={`xxs`} />
  </components.DropdownIndicator>
)

const GeneralStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: () => ({
    font: "inherit",
    WebkitOverflowScrolling: "touch",
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    overflow: "hidden",
    padding: 0,
    position: "relative",
  }),
  menu: () => ({
    font: "inherit",
    backgroundColor: "#fff",
    borderTop: 0,
    boxSizing: "border-box",
    marginTop: "-1px",
    label: "menu",
    position: "absolute",
    top: "100%",
    width: "100%",
    zIndex: 1,
  }),
}

const Styles = {
  wishlist: {
    control: (provided, state) => {
      const bgColor = state.hasValue ? (state.getValue()[0].available ? `#fff` : `#DFD8CF`) : `#fff`
      const textColor = state.hasValue
        ? state.getValue()[0].available
          ? `#2b2b2b`
          : `#ABA49C`
        : `#2b2b2b`
      return {
        width: "100%",
        backgroundColor: bgColor,
        color: textColor,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        border: "1px solid #f4f1ee",
        borderRadius: 0,
        transition: "all 0.2s ease",
        padding: "9px 20px 9px 26px",
      }
    },
    option: (provided, state) => {
      const bgColor = state.data.available ? `#fff` : `#DFD8CF`
      const textColor = state.data.available ? `#2b2b2b` : `#ABA49C`
      return {
        ...provided,
        backgroundColor: bgColor,
        color: textColor,
      }
    },
    singleValue: provided => ({
      ...provided,
      color: "inherit",
      font: "inherit",
    }),
    ...GeneralStyles,
  },
  form: {
    control: () => ({
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      border: "0px solid",
      borderBottom: "1px solid #aba49c",
      borderRadius: 0,
      transition: "all 0.2s ease",
      padding: "0 0 4px 0",
    }),
    ...GeneralStyles,
  },
  cart: {
    control: () => ({
      font: "inherit",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      border: "1px solid #2b2b2b",
      borderRadius: 0,
      transition: "all 0.2s ease",
      padding: "2px 10px",
    }),
    ...GeneralStyles,
  },
}

const FormDropdown = ({ name, options, value, handleChange, placeholder, className, layout }) => {
  return (
    <div className={className}>
      <Select
        components={{ DropdownIndicator }}
        styles={Styles[layout]}
        name={name}
        placeholder={placeholder}
        options={options}
        value={options.find(option => option.value === value)}
        onChange={option => handleChange(option.value, name)}
      />
    </div>
  )
}

FormDropdown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  wishlist: PropTypes.bool,
  layout: PropTypes.oneOf(Object.keys(Styles)),
}

FormDropdown.defaultProps = {
  layout: `form`,
}

export default FormDropdown
