import styled from "@emotion/styled"
import tw from "twin.macro"

export const ImageWrapper = styled.div`
  ${tw`relative`}
  ${({ fullWidth }) => (fullWidth ? tw`w-full-vw` : tw`w-full`)}
  ${({ onClick }) => (onClick ? tw`cursor-pointer` : null)}
`

export const ImageRatio = styled.div`
  padding-bottom: ${({ ratio }) => (ratio.split("/")[1] / ratio.split("/")[0]) * 100}%;
`

export const StyledImage = styled.img`
  ${({ cover }) => (cover ? tw`absolute inset-0 object-cover object-center h-full w-full` : null)}
  ${({ hover }) =>
    hover
      ? tw`opacity-0 md:group-hover:opacity-100 pointer-events-none md:group-hover:pointer-events-auto transition-all duration`
      : null}
`
