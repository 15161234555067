import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import Accordion from "../Accordion"
import { NavigationProps } from "../../props/NavigationProps"
import { withNavigationFooter } from "./withNavigationFooter"

const Wrapper = tw.div`w-full md:w-4/5`

const Desktop = tw.div`
  hidden w-full md:flex flex-wrap pb-1-6
`

const Mobile = tw.div`
  block md:hidden
`

const DesktopList = tw.ul`
  md:w-1/2 lg:w-1/4 block pb-1-6
`

const MobileList = tw.ul`pb-3-2`

const Title = tw.p`
  block text-16 leading-1.75 mb-1-6 font-medium
`

const ListItem = styled(Link)`
  ${tw`block text-16 leading-2.5 hover:text-orange transition-all duration`}
`

const NavigationFooter = withNavigationFooter(({ items }) => {
  return (
    <Wrapper>
      <Desktop>
        {items?.map((item, index) => (
          <DesktopList key={index} subNavigation={item}>
            <Title>{item.title}</Title>
            {item.items?.map((listItem, index) => (
              <ListItem key={index} to={listItem.url}>
                {listItem.title}
              </ListItem>
            ))}
          </DesktopList>
        ))}
      </Desktop>
      <Mobile>
        {items?.map((item, index) => (
          <Accordion key={index} title={item.title} size={`footer`} icon={item.items?.length > 0}>
            <MobileList>
              {item.items?.map((listItem, index) => (
                <ListItem key={index} to={listItem.url}>
                  {listItem.title}
                </ListItem>
              ))}
            </MobileList>
          </Accordion>
        ))}
      </Mobile>
    </Wrapper>
  )
})

NavigationFooter.propTypes = NavigationProps

export default NavigationFooter
