import React, { memo } from "react"

import ShopifyImage from "./ShopifyImage"
import GatsbyImage from "./GatsbyImage"
import { ImageProps } from "../../props/ImageProps"

const Image = ({ alt, cover, onClick, ratio, image, fullHeight, fullWidth, hoverImage }) => {
  switch (typeof image) {
    case `string`:
      return <ShopifyImage alt={alt} onClick={onClick} ratio={ratio} src={image} cover={cover} fullWidth={fullWidth} hoverSrc={hoverImage} />
    case `object`:
      return <GatsbyImage alt={alt} onClick={onClick} ratio={ratio} image={image} fullHeight={fullHeight} />
    default:
      return null
  }
}

Image.propTypes = ImageProps

Image.defaultProps = {
  alt: ``,
  cover: false,
  onClick: () => {},
  ratio: null,
  fullHeight: false,
  fullWidth: false,
}

export default memo(Image)
