import React from "react"
import { ApolloClient, ApolloProvider, ApolloConsumer, HttpLink, InMemoryCache } from "@apollo/client"

import { useLocation } from "../hooks/useLocation"

export const ShopifyProvider = ({ children, config }): JSX.Element => {
  const { shopify: currentConfig } = useLocation()

  const shopifyConfig = {
    ...config,
    ...(currentConfig?.shopName && { ...currentConfig }),
  }

  const client = new ApolloClient({
    link: new HttpLink({
      uri: `https://${shopifyConfig?.shopName}.myshopify.com/api/${shopifyConfig?.apiVersion}/graphql.json`,
      headers: {
        "X-Shopify-Storefront-Access-Token": shopifyConfig?.accessToken,
      },
    }),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const withShopify = Component => (props): JSX.Element => <ApolloConsumer>{client => <Component {...props} shopify={client} />}</ApolloConsumer>
