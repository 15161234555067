import { useCallback } from "react"
import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useShopify } from "./useShopify"

export const useShop = () => {
  const {
    graphql: {
      queries: { GET_SHOP_SETTINGS },
    },
  } = useCore()
  const { setShop } = useApp()
  const { useQuery } = useShopify()
  const { refetch: getQueryShop } = useQuery(GET_SHOP_SETTINGS, { skip: true })

  const getShop = useCallback(async () => {
    const {
      data: { shop },
      loading,
    } = await getQueryShop()
    if (!loading) setShop(shop)
  }, [getQueryShop, setShop])

  return {
    getShop,
  }
}
