import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withWishlistWidget } from "./withWishlistWidget"
import Icon from "../../Icon"

const StyledWishlist = styled(Link)`
  ${tw`hidden lg:block`}
`

const StyledIcon = styled(Icon)`
  ${tw`p-1-2 md:p-1-6 hover:text-orange transition-all duration`}
`

export const WishlistWidget = withWishlistWidget(({ wishlistUrl }) => (
  <StyledWishlist to={wishlistUrl}>
    <StyledIcon name={`wishlist`} size={`small`} />
  </StyledWishlist>
))
