import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import ModalsDrawer from "../Modals/ModalsDrawer"
import CartLineitemCard from "./CartLineitemCard"
import CartDiscountCode from "./CartDiscountCode"
import CartTotals from "./CartTotals"
import { StyledButton } from "../Styled/Button"
import Icon from "../Icon"
import { withCartDrawer } from "./withCartDrawer"
import { navigate } from "gatsby"

const Wrapper = tw.div`
  h-full overflow-x-hidden px-2-4 pb-6-4
`

const Title = tw.h2`
  text-28 leading-1.29  md:text-32 md:leading-1.31 my-2-8 md:my-3-2 text-center font-display
`

const Lineitems = tw.div`
  border-b border-beige
`

const Card = styled(CartLineitemCard)`
  ${tw`border-t border-beige`}
`

const Discount = styled(CartDiscountCode)`
  ${tw`my-3-2`}
`

const Totals = styled(CartTotals)`
  ${tw`mb-1-6`}
`

const Checkout = styled(StyledButton)`
  ${tw`w-full mb-1-6`}
`

const ViewBag = tw.button`
  focus:outline-none w-full leading-1.56 mb-4-8
`

const KeepShopping = styled(StyledButton)`
  ${tw`w-full`}
`

const PaymentIcons = tw.div`
  flex justify-between max-w-33 mx-auto
`

const CartDrawer = withCartDrawer(({ title, emptyTitle, activeCart, setActiveCart, lineItems, cartItemsQuantity, keepShopping, webUrl, cartUrl }) => {
  return (
    <ModalsDrawer active={activeCart} setActive={setActiveCart} right>
      <Wrapper>
        <Title>{cartItemsQuantity > 0 ? title : emptyTitle}</Title>
        {cartItemsQuantity > 0 ? (
          <>
            <Lineitems>
              {lineItems?.map((lineitem, index) => (
                <Card key={index} lineitem={lineitem} layout={`drawer`} />
              ))}
            </Lineitems>
            <Discount layout={`drawer`} />
            <Totals layout={`drawer`} />
            <Checkout height={`56`} textSize={`16`} colour={`orange`} onClick={() => navigate(webUrl)}>
              Checkout
            </Checkout>
            <ViewBag
              className={`underlined-black`}
              onClick={() => {
                navigate(cartUrl)
                setActiveCart(false)
              }}
            >
              <span>View bag</span>
            </ViewBag>
            <PaymentIcons>
              <Icon name={`paypal`} size={`none`} height={`32`} width={`42`} />
              <Icon name={`visa`} size={`none`} height={`32`} width={`42`} />
              <Icon name={`americanExpress`} size={`none`} height={`32`} width={`42`} />
              <Icon name={`applePay`} size={`none`} height={`32`} width={`42`} />
              <Icon name={`mastercard`} size={`none`} height={`32`} width={`42`} />
            </PaymentIcons>
          </>
        ) : (
          <KeepShopping
            height={`56`}
            textSize={`16`}
            colour={`green`}
            onClick={() => {
              navigate(keepShopping?.link)
              setActiveCart(false)
            }}
          >
            {keepShopping.title}
          </KeepShopping>
        )}
      </Wrapper>
    </ModalsDrawer>
  )
})

CartDrawer.propTypes = {
  activeCart: PropTypes.bool,
  setActiveCart: PropTypes.func,
}

export default CartDrawer

//TODO --- Further work with Cart page
