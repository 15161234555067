import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withFooterSocialLinks } from "./withFooterSocialLinks"
import Icon from "../Icon"

const List = tw.ul`
  w-full md:w-1/5 md:relative
`

const ListItem = tw.div`
  w-full flex flex-wrap border-t border-b border-grey md:border-0 md:relative md:bottom-2-4
`

const IconWrapper = styled.a`
  ${tw`block w-1/4 flex items-center justify-center h-8`}
  ${({ last }) => (last ? tw`border-0` : tw`border-r border-grey md:border-r-0`)}
`

const StyledIcon = styled(Icon)`
  ${tw`hover:text-orange transition-all duration`}
`

const FooterSocialLinks = withFooterSocialLinks(({ items }) => {
  return (
    <List>
      <ListItem>
        {items?.map((item, index) => (
          <IconWrapper href={item.url} key={item.title} last={index === items.length - 1}>
            <StyledIcon name={item.title.toLowerCase()} />
          </IconWrapper>
        ))}
      </ListItem>
    </List>
  )
})

FooterSocialLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

export default FooterSocialLinks
