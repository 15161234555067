import React from "react"
// import PropTypes from "prop-types"
import tw from "twin.macro"

import SearchGlobalResultsProductCard from "./SearchGlobalResultsProductCard"

const BgContainer = tw.div`
  fixed top-8-6 md:top-12-3 left-0 right-0 bottom-0 bg-green opacity-50 cursor-pointer
`

const Results = tw.div`
  fixed left-0 right-0 md:absolute top-8-6 md:top-8-8 md:max-w-42 lg:max-w-60 w-full bg-lightest
`

const Wrapper = tw.div`
  px-2-4 md:px-3-2 py-1-6
`

const SeeAllWrapper = tw.div`
  flex justify-end w-full my-0-8
`

const SeeAll = tw.button`
  leading-1.36 hover:text-orange focus:outline-none
`

const Fakedata = [
  {
    title: "Honeyeater",
    subTitle: "Plum susde heels",
    price: {
      amount: 25900,
      currencyCode: "AUD",
    },
    link: "/product",
    image:
      "https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3.jpg?v=1588835297",
  },
  {
    title: "Honeyeater",
    subTitle: "Plum susde heels",
    price: {
      amount: 25900,
      currencyCode: "AUD",
    },
    link: "/product",
    image:
      "https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3.jpg?v=1588835297",
  },
]

const SearchGlobalResults = ({ setActiveSearch }) => {
  return (
    <>
      <BgContainer onClick={() => setActiveSearch(false)} />
      <Results>
        <Wrapper>
          {Fakedata.map((result, index) => (
            <SearchGlobalResultsProductCard
              key={index}
              image={result?.image}
              link={result?.link}
              title={result?.title}
              subTitle={result?.subTitle}
              price={result?.price}
            />
          ))}
          <SeeAllWrapper>
            <SeeAll className={`underlined-black`}>
              <span>See all</span>
            </SeeAll>
          </SeeAllWrapper>
        </Wrapper>
      </Results>
    </>
  )
}

export default SearchGlobalResults

//TODO - PropTypes
//TODO - setup search and filter package when start work with Search and CLP
