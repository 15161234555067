import React, { useMemo } from "react"

import { useLocation } from "../../hooks/useLocation"
import { useApp } from "../../hooks/useApp"
import { useCheckout } from "../../hooks/useCheckout"

export const withCartWidget = Component =>
  React.memo(({ name = "CartTotal" }) => {
    const { location } = useLocation()
    const { shop, setActiveCart, setActiveSearch, setActiveMenu } = useApp()
    const { checkout, createCheckout, migrateCheckout, cartItemsQuantity } = useCheckout()

    if (!checkout) createCheckout()
    if (shop && shop?.paymentSettings?.countryCode !== location) migrateCheckout(shop?.currencyCode)

    Component.displayName = name
    return useMemo(
      () => <Component quantity={cartItemsQuantity} setActiveCart={setActiveCart} setActiveSearch={setActiveSearch} setActiveMenu={setActiveMenu} />,
      [cartItemsQuantity, setActiveCart, setActiveSearch, setActiveMenu]
    )
  })
