module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","name":"Bared Footwear","short_name":"Bared Footwear","description":"Look good, feel better. Designed by a podiatrist, Bared Footwear offers seriously stylish shoes for sneaker comfort, everyday. Unbeatable customer service. Free delivery. Free returns.","background_color":"#FFFF04","theme_color":"#000000","display":"minimal-ui","icon":"static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7bd1fcb9f69403dafea3d7ce7d870a17"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
