import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import Image from "../Image/Image"
import NavigationHeaderSubShoeIcon from "./NavigationHeaderSubShoeIcon"
import { NavigationProps } from "../../props/NavigationProps"

const Container = tw.div`
  fixed top-12-3 left-0 hidden lg:flex flex-row w-full bg-lightest text-dark shadow-header opacity-0 group-hover:opacity-100  pointer-events-none group-hover:pointer-events-auto transition-all duration-slow border-t border-beige
`

const Wrapper = tw.div`
  w-full flex flex-row justify-between max-w-xl mx-auto px-14-4 pt-4 pb-5-6
`
const MenuList = tw.ul`
  flex flex-col w-1/10
`

const IconListWrapper = tw.div`
  w-4/10
`

const IconList = tw.ul`
  flex flex-wrap w-full
`

const MenuItem = styled.div`
  ${tw`py-1-6 text-16 leading-1.12 hover:text-orange transition-all duration`}
  ${({ sale }) => (sale ? tw`font-medium` : null)}
`
const ImageWrapper = tw.div`
  w-3/10
`

const NavigationHeaderSub = ({ subNavigation }) => {
  return (
    <Container>
      <Wrapper>
        <MenuList>
          {subNavigation.items?.map((item, index) => (
            <MenuItem
              as={item.url ? Link : `div`}
              key={`${item.title}-${index}`}
              to={item.url}
              sale={item.title.toLowerCase().includes(`sale`) ? "true" : undefined}
            >
              {item.title}
            </MenuItem>
          ))}
        </MenuList>
        <IconListWrapper>
          <IconList>
            {subNavigation.contents?.map((content, index) => (
              <NavigationHeaderSubShoeIcon key={`${content.title}-${index}`} title={content.title} icon={content.icon} url={content.url} />
            ))}
          </IconList>
        </IconListWrapper>
        <ImageWrapper>
          <Image image={subNavigation?.image?.asset?.url} ratio={`1/1`} alt={subNavigation?.image?.alt} />
        </ImageWrapper>
      </Wrapper>
    </Container>
  )
}

NavigationHeaderSub.propTypes = NavigationProps

export default NavigationHeaderSub
