import React from "react"
import { Global, css } from "@emotion/core"
import tw from "twin.macro"

import { styles } from "../Styled/Text"

const Globals = () => {
  return (
    <Global
      styles={css`
        html {
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          -ms-overflow-style: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          &::-webkit-scrollbar {
            display: none;
          }
          &:focus {
            outline: none !important;
          }
          ${tw`font-body text-16 font-normal leading-1`}
        }
        html,
        body,
        #___gatsby,
        div[role="group"][tabindex] {
          ${tw`h-full`}
        }
        html {
          &.is-hidden body {
            ${tw`overflow-hidden`}
          }
        }

        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
          -webkit-appearance: none;
        }

        #gorgias-web-messenger-container {
          ${tw`z-20`}
        }
        #gatsby-focus-wrapper {
          ${tw`flex flex-col`}
        }

        .rte {
          ${tw`whitespace-normal break-words`}
          ol,
          ul {
            list-style: none;
            li {
              margin-bottom: 0.25rem;
              display: flex;
            }
            li:before {
              content: "•";
              font-size: 1rem;
              padding-right: 1rem;
              padding-top: 0.325rem;
            }
          }
          p {
            strong,
            b {
              ${tw`font-medium font-body`}
            }
            em,
            i {
              ${tw`italic`}
            }
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            ${tw`pb-2-4`}
          }
          img {
            ${tw`mx-auto`}
          }
          h1 {
            ${styles.H1}
          }
          h2 {
            ${styles.H2}
          }
          h3 {
            ${styles.H3}
          }
          h4 {
            ${styles.H4}
          }
          h5 {
            ${styles.H5}
          }
          h6 {
            ${styles.Small}
          }
          p {
            ${styles.P}
          }
          ol {
            ${tw`list-decimal`}
          }
          ul {
            ${tw`list-disc `}
          }
          a {
            ${tw`underline`}
          }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .rte {
          em {
            ${tw`font-display font-normal`}
          }
          strong {
            ${tw`font-display font-normal not-italic`}
          }
        }
        .underlined-white {
          span {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              background: #fff;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 1px;
            }
          }
        }
        .underlined-black {
          span {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              background: #000;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 1px;
            }
          }
          &:hover {
            span:after {
              background: #ba5c27;
            }
          }
        }
        .hide-scrollbar {
          -ms-overflow-style: none; /* IE, Edge */
          scrollbar-width: none;
        }

        .hide-scrollbar::-webkit-scrollbar {
          width: 0px; /* Remove scrollbar space */
          height: 0px; /* Remove scrollbar space */
          background: transparent; /* Optional: just make scrollbar invisible */
        }
      `}
    />
  )
}

export default Globals
