import React, { useState } from "react"

export const AppContext = React.createContext({})

export const AppProvider = ({ children, config: siteConfig }) => {
  const [config] = useState(siteConfig || {})
  const [shop, setShop] = useState(false)
  const [checkout, setCheckout] = useState(false)
  const [customer, setCustomer] = useState(false)
  const [wishlist, setWishlist] = useState([])
  const [activeCart, setActiveCart] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const [activeSubscribe, setActiveSubscribe] = useState(false)
  const [activeMenu, setActiveMenu] = useState(false)
  const [selectedStore, setSelectedStore] = useState("")

  const values = {
    config,
    shop,
    setShop,
    checkout,
    setCheckout,
    customer,
    setCustomer,
    wishlist,
    setWishlist,
    activeCart,
    setActiveCart,
    activeSearch,
    setActiveSearch,
    activeSubscribe,
    setActiveSubscribe,
    activeMenu,
    setActiveMenu,
    selectedStore,
    setSelectedStore,
  }

  return <AppContext.Provider value={{ ...values }}>{children}</AppContext.Provider>
}

export const withApp = Component => props => <AppContext.Consumer>{values => <Component {...props} layout={values} />}</AppContext.Consumer>
