import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withCurrency } from "./withCurrency"

const Wrapper = tw.div`
  z-10
`

const Selected = tw.button`
  focus:outline-none flex items-center justify-center w-7-2 md:w-10
`

const Selector = styled.div`
  ${tw`absolute flex flex-row z-50 w-full transform transition-all duration`}
  ${({ active }) => (active ? tw`visible opacity-100 pointer-events-auto` : tw`invisible opacity-0 pointer-events-none`)}
`

const Option = tw.button`
  flex items-center justify-center w-full max-w-10 h-5-6 md:h-8-8 text-dark hover:text-lightest bg-light hover:bg-green focus:outline-none
`

const Currency = withCurrency(({ currency, currencies = [], updateCurrency, selectorActive, setSelectorActive }) => {
  return (
    <Wrapper>
      <Selected onClick={() => setSelectorActive(!selectorActive)}>{currency}</Selected>
      <Selector active={selectorActive}>
        {currencies?.map(item => (
          <Option
            key={item}
            onClick={() => {
              updateCurrency(item)
              setSelectorActive(false)
            }}
            disabled={item === currency}
          >
            {item}
          </Option>
        ))}
      </Selector>
    </Wrapper>
  )
})

Currency.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.string),
  currency: PropTypes.string,
  updateCurrency: PropTypes.func,
  selectorActive: PropTypes.bool,
  setSelectorActive: PropTypes.func,
}

export default Currency
