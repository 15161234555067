import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import NavigationHeader from "../Navigation/NavigationHeader"
import HeaderAccount from "./HeaderAccount"
import HeaderBrand from "./HeaderBrand"
import Announcement from "../Announcement/Announcement"
import Currency from "../Currency/Currency"
import HeaderTopBarMenu from "./HeaderTopBarMenu"
import ThemeContext from "../Layout/LayoutContext"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { NavigationProps } from "../../props/NavigationProps"

const BgContainer = styled(BackgroundContainer)`
  ${tw`fixed z-20 top-0`}
`

const Container = styled(StyledContainer)`
  ${tw`relative flex flex-row justify-between max-w-xl mx-auto px-1-6 lg:px-0-8`}
`

const TopBar = styled(StyledContainer)`
  ${tw`relative flex flex-row justify-start max-w-xl mx-auto text-12 leading-2.5 md:text-14 md:leading-2.57 bg-green text-lightest`}
`

const Header = ({ title, headerNavigation, topBarNavigation }) => {
  const { activeCart, setActiveCart, activeMenu, setActiveMenu, activeSearch, setActiveSearch } = useContext(ThemeContext)
  return (
    <BgContainer background={`lightest`}>
      <BackgroundContainer background={`green`}>
        <TopBar width={`full`}>
          <Announcement />
          <Currency />
          <HeaderTopBarMenu navigation={topBarNavigation} />
        </TopBar>
      </BackgroundContainer>
      <Container width={`full`}>
        <NavigationHeader />
        <HeaderBrand title={title} />
        <HeaderAccount
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          activeCart={activeCart}
          setActiveCart={setActiveCart}
          activeSearch={activeSearch}
          setActiveSearch={setActiveSearch}
        />
      </Container>
    </BgContainer>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  headerNavigation: PropTypes.shape(NavigationProps),
  announcement: PropTypes.shape({
    message: PropTypes.string,
    link: PropTypes.string,
  }),
  currencies: PropTypes.arrayOf(PropTypes.string),
  topBarNavigation: PropTypes.shape(NavigationProps),
}

export default Header
