import React from "react"

import { useApp } from "../../hooks/useApp"
import { useCheckout } from "../../hooks/useCheckout"
import { usePage } from "../../hooks/usePage"

export const withCartDrawer = Component => ({ name = "CartDrawer", page }) => {
  const { cartItemsQuantity, checkout, webUrl } = useCheckout()
  const { activeCart, setActiveCart } = useApp()
  const {
    cart: { title, emptyTitle, keepShopping },
  } = usePage()
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const cartUrl = `${routes.CART}`
  Component.displayName = name
  return (
    <Component
      title={title}
      emptyTitle={emptyTitle}
      activeCart={activeCart}
      setActiveCart={setActiveCart}
      lineItems={checkout?.lineItems}
      cartItemsQuantity={cartItemsQuantity}
      keepShopping={keepShopping}
      webUrl={webUrl}
      cartUrl={cartUrl}
    />
  )
}
