import React from "react"
import * as Search from "@appbaseio/reactivesearch"

export const SearchProvider = ({ config, children }): JSX.Element => {
  return (
    <Search.ReactiveBase
      url={`${config?.searchUrl}`}
      type={config?.searchDocument}
      app={config?.searchIndex}
      theme={{
        typography: {
          fontFamily: "inherit",
        },
        colors: {
          textColor: "#000",
          backgroundColor: "#fff",
          primaryTextColor: "#000",
          primaryColor: "#000",
          titleColor: "#000",
          alertColor: "#e04e5f",
          borderColor: "#c9cacc",
        },
      }}
    >
      {children}
    </Search.ReactiveBase>
  )
}
export const SearchComponents = {
  ...Search,
}
