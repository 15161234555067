import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const StyledMain = tw.main`
  pt-8-6 md:pt-12-3 flex-1
`

const Main = ({ children }) => {
  return <StyledMain>{children}</StyledMain>
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
