import React, { memo, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { scrollHidden } from "../../utils/domUtils"
import Icon from "../Icon"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"

const Container = styled.div`
  ${tw`fixed z-20 inset-0 mt-8-6 md:mt-12-3 flex items-center justify-center transition-all`}
  ${({ active }) => (active ? tw`visible` : tw`invisible`)}
  ${({ active }) => (active ? tw`duration-slow` : ``)}
`

const Bg = styled.div`
  ${tw`fixed bg-green inset-0 top-8-6 md:top-12-3 cursor-pointer transition-all`}
  ${({ active }) => (active ? tw`opacity-50` : tw`opacity-0`)}
  ${({ active }) => (active ? tw`duration-slow` : ``)}
`

const ContentWrapper = styled.div`
  ${tw`w-full transition-all absolute z-10`}
  ${({ active }) => (active ? tw`duration-slow translate-y-0` : tw`translate-y-full`)}
  ${({ active }) => (active ? tw`opacity-100 pointer-events-auto` : tw`opacity-0 pointer-events-none`)}
  ${({ noOverflow }) => (!noOverflow ? tw`max-h-8/10-vh overflow-x-hidden overflow-y-auto` : ``)}
  ${({ sizeGuide, notify }) =>
    sizeGuide
      ? tw`max-w-41 top-0 md:top-auto md:max-h-8/10-vh overflow-x-hidden overflow-y-auto`
      : notify
      ? tw`max-w-47 px-2-4 lg:px-0`
      : tw`max-w-100 px-2-4 lg:px-0`}
  @media (max-width: 767px) {
    max-height: ${({ sizeGuide }) => (sizeGuide ? `calc(100vh - 86px)` : null)}
  }
`
const Content = styled.div`
  ${tw`relative flex flex-col items-center justify-center bg-lightest`}
`

const StyledIcon = styled(Icon)`
  ${tw`block pt-1 pr-1`}
`
const Close = styled.button`
  ${tw`absolute top-0 right-0 cursor-pointer hover:opacity-75 focus:outline-none z-50`}
`

const ModalsPopup = ({ active, children, setActive, noOverflow, subscribe, sizeGuide, notify }) => {
  useEffect(() => {
    scrollHidden(active)
  }, [active])

  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  return (
    <Container active={active}>
      <Bg
        active={active}
        onClick={() => {
          setActive(false)
          if (subscribe) {
            storage.set(keys?.subscribe, "hidden")
          }
        }}
      />
      <ContentWrapper className={`hide-scrollbar`} active={active} noOverflow={noOverflow} sizeGuide={sizeGuide} notify={notify}>
        <Content active={active}>
          <Close
            onClick={() => {
              setActive(false)
              if (subscribe) {
                storage.set(keys?.subscribe, "hidden")
              }
            }}
            title={`close`}
          >
            <StyledIcon name={`close`} size={`sm`} />
          </Close>
          {children}
        </Content>
      </ContentWrapper>
    </Container>
  )
}

ModalsPopup.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  setActive: PropTypes.func,
  noOverflow: PropTypes.bool,
  subscribe: PropTypes.bool,
  sizeGuide: PropTypes.bool,
  notify: PropTypes.bool,
}

export default memo(ModalsPopup)
