import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledButton } from "../Styled/Button"
import Icon from "../Icon"
import ModalsPopup from "../Modals/ModalsPopup"
import ImageContent from "../ImageContent"
import { withSubscribePopup } from "./withSubscribePopup"

const Container = tw.div`
  flex flex-col items-center justify-between pt-5-6 pb-6-4 px-3-2 md:px-6-4
`
const Title = tw.h3`
  font-display text-28 leading-1.29 md:text-44 md:leading-1.45 mb-1-6 text-center
`

const Description = styled.div`
  ${tw`mb-3-2 `}
  .rte {
    p {
      ${tw`text-center`}
    }
  }
`

const OptionsWrapper = tw.div`
  flex items-center flex-col mb-2-4 md:mb-3-2
`

const OptionsTitle = tw.p`
  text-16 leading-1.81 mb-1-6 
`

const Options = tw.div`
  flex flex-row text-16 leading-1
`

const Option = tw.button`
  focus:outline-none mx-0-8 flex flex-row items-center
`

const OptionTitle = tw.p`
  pl-0-4 text-16 leading-1 pb-0-2
`

const Email = tw.form`
  h-5-6 flex items-center justify-between border border-dark px-1-6 w-full max-w-33-6 mb-1-6
`

const Input = styled.input`
  ${tw`flex-1 focus:outline-none`} ::placeholder {
    ${tw`text-dark`}
  }
`

const Submit = tw.button`
  focus:outline-none
`

const Error = tw.div`
  text-12 mb-1-6 text-orange
`

const Terms = styled.div`
  ${tw`max-w-27-2 w-full `}
  .rte {
    p {
      ${tw`text-12 leading-1.67 opacity-75 text-center`}
    }
  }
`

const SbumitIcon = styled(Icon)`
  ${tw`hover:transform hover:translate-x-0-4 transition-transform duration-slow`}
`

const Button = styled(StyledButton)`
  ${tw`w-full`}
`

const SubscribePop = withSubscribePopup(
  ({
    data,
    handleChange,
    handleSubmit,
    errors,
    options,
    title,
    description,
    successTitle,
    successMessage,
    terms,
    image,
    active,
    setActive,
    tags,
    toogleTag,
    success,
  }) => {
    return (
      <ModalsPopup active={active} setActive={setActive} subscribe>
        <ImageContent image={image} mobileHidden>
          {!success ? (
            <Container>
              <Title>{title}</Title>
              <Description>
                <div className={`rte`}>{description}</div>
              </Description>
              {options && (
                <OptionsWrapper>
                  <OptionsTitle>I am interested in</OptionsTitle>
                  <Options>
                    {options.map((option, index) => (
                      <Option key={`${option.title}-${index}`} onClick={() => toogleTag(option.tag)}>
                        {tags.indexOf(option.tag) === -1 ? (
                          <Icon name={`tickedBoxInactive`} size={`xxs`} />
                        ) : (
                          <Icon name={`tickedBoxActive`} size={`xxs`} />
                        )}
                        <OptionTitle>{option.title}</OptionTitle>
                      </Option>
                    ))}
                  </Options>
                </OptionsWrapper>
              )}
              <Email onSubmit={handleSubmit}>
                <Input name={`email`} tpye={`email`} placeholder={`Email`} value={data?.email} onChange={handleChange} required />
                <Submit type={`submit`}>
                  <SbumitIcon name={`vintageArrow`} size={`small`} />
                </Submit>
              </Email>
              {errors?.length > 0 &&
                errors?.filter(error => error?.field?.length && error?.field[1] === "email").map(error => <Error>{error?.message}</Error>)}
              <Terms>
                <div className={`rte`}>{terms}</div>
              </Terms>
            </Container>
          ) : (
            <Container>
              <Title>{successTitle}</Title>
              <Description>{successMessage}</Description>
              <Button
                colour={`green`}
                height={`56`}
                textSize={`16`}
                onClick={() => {
                  setActive(false)
                }}
              >
                Keep Shopping
              </Button>
            </Container>
          )}
        </ImageContent>
      </ModalsPopup>
    )
  }
)

export default SubscribePop
