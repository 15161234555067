import PropTypes from "prop-types"
import styled from "@emotion/styled/macro"
import tw from "twin.macro"

const styles = {
  textSize: {
    "14": tw`text-14`,
    "16": tw`text-16`,
    "16-14": tw`text-14 sm:text-16`,
    "20": tw`text-20`,
    "28-20": tw`text-20 md:text-28 font-display leading-1`,
    "32": tw`text-20 sm:text-32 font-display`,
  },
  height: {
    "32": tw`h-3-2`,
    "48-32": tw`h-3-2 sm:h-4-8`,
    "40": tw`h-4`,
    "48": tw`h-4-8`,
    "48-40": tw`h-4 md:h-4-8`,
    "56": tw`h-5-6`,
    "56-40": tw`h-4 sm:h-5-6`,
    "56-34": tw`h-3-4 sm:h-5-6`,
    "64-56": tw`h-5-6 md:h-6-4`,
    "72-56": tw`h-5-6 sm:h-7-2`,
    "72": tw`h-7-2`,
    "88": tw`h-8-8`,
    "88-56": tw`h-5-6 md:h-8-8`,
  },
  colour: {
    transparent: tw`bg-transparent text-dark  hover:text-light hover:bg-green active:bg-orange`,
    light: tw`bg-light text-dark hover:text-light hover:bg-green active:bg-orange`,
    orange: tw`bg-orange text-light`,
    green: tw`bg-green text-light`,
    lightest: tw`bg-lightest text-dark hover:text-light border-green hover:bg-green active:bg-orange`,
  },
  disabled: tw`cursor-not-allowed`,
  icon: tw`justify-start sm:justify-between pl-2-4 sm:pl-4 pr-3-2`,
  border: tw`border`,
}

export const StyledButton = styled.button`
  ${tw`flex flex-row items-center justify-center transition-all duration outline-none focus:outline-none cursor-pointer`}
  ${({ colour }) => (colour ? styles.colour[colour] : null)}
  ${({ icon }) => (icon ? styles.icon : null)}
  ${({ disabled }) => (disabled ? styles.disabled : null)}
  ${({ height }) => (height ? styles.height[height] : null)}
  ${({ textSize }) => (textSize ? styles.textSize[textSize] : null)}
  ${({ border }) => (border ? styles.border : null)}
`

StyledButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  textSize: PropTypes.oneOf(Object.keys(styles.textSize)).isRequired,
  colour: PropTypes.oneOf(Object.keys(styles.colour)).isRequired,
  height: PropTypes.oneOf(Object.keys(styles.height)).isRequired,
  border: PropTypes.bool,
}

StyledButton.defaultProps = {
  disabled: null,
  icon: null,
  textSize: "",
  colour: "",
  height: "",
  border: false,
}
