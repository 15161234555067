import React, { memo, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { scrollHidden } from "../../utils/domUtils"
import Icon from "../Icon"

const Container = styled.div`
  ${tw`fixed z-50 left-0 right-0 bottom-0 top-8-6 md:top-12-3 transition-all`}
  ${({ active }) => (active ? tw`visible` : tw`invisible`)}
  ${({ active }) => (active ? tw`duration-slow` : ``)}
`

const Bg = styled.div`
  ${tw`fixed bg-green left-0 right-0 bottom-0 top-8-6 md:top-12-3 cursor-pointer transition-all`}
  ${({ active }) => (active ? tw`opacity-50` : tw`opacity-0`)}
  ${({ active }) => (active ? tw`duration-slow` : ``)}
`

const ContentWrapper = styled.div`
  ${tw`w-full transition-all absolute z-30 max-w-49 h-full min-h-full-vh`}
  ${({ right }) => (right ? tw`right-0` : null)}
  ${({ active }) => (active ? tw`duration-slow translate-y-0` : tw`translate-y-full`)}
  ${({ active }) => (active ? tw`opacity-100` : tw`opacity-0`)}
`
const Content = styled.div`
  ${tw`overflow-x-hidden overflow-y-auto bg-lightest h-full pb-8-6 md:pb-12-3`}
`

const StyledIcon = styled(Icon)`
  ${tw`block pt-1 pr-1`}
`
const Close = styled.button`
  ${tw`absolute right-0 top-0 cursor-pointer hover:opacity-75 focus:outline-none z-10`}
  ${({ noClose }) => (noClose ? tw`hidden` : null)}
`

const ModalsDrawer = ({ active, setActive, children, right, noClose }) => {
  useEffect(() => {
    scrollHidden(active)
  }, [active])
  return (
    <Container active={active}>
      <Bg active={active} onClick={() => setActive(false)} />
      <ContentWrapper active={active} right={right}>
        <Content active={active}>
          <Close noClose={noClose} onClick={() => setActive(false)} title={`close`}>
            <StyledIcon name={`close`} size={`small`} />
          </Close>
          {children}
        </Content>
      </ContentWrapper>
    </Container>
  )
}

ModalsDrawer.propTypes = {
  active: PropTypes.bool,
  setActive: PropTypes.func,
  children: PropTypes.node,
  right: PropTypes.bool,
  noClose: PropTypes.bool,
}

export default memo(ModalsDrawer)
