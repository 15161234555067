import { useStaticQuery, graphql } from "gatsby"

export const usePage = () => {
  const { cart } = useStaticQuery(graphql`
    query PAGE {
      cart: sanityPageCart {
        title
        emptyTitle
        freeShippingThreshold
        keepShopping {
          title
          link
        }
      }
    }
  `)

  return {
    cart,
  }
}
