import React, { useMemo } from "react"

import { useNavigation } from "../../hooks/useNavigation"

export const withNavigationFooter = Component =>
  React.memo(({ name = "NavigationFooter" }) => {
    const { footer: items } = useNavigation()

    Component.displayName = name
    return useMemo(() => <Component items={items} />, [items])
  })
