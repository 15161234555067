import React, { useState, useEffect } from "react"

import { useAnnouncement } from "../../hooks/useAnnouncement"
import { useSettings } from "../../hooks/useSettings"

export const withAnnouncement = Component => ({ name = "Announcement" }) => {
  const { items } = useAnnouncement()
  const { announcements } = useSettings()
  const [active, setActive] = useState(0)

  const length = items.length - 1
  const duration = announcements?.duration || 5

  useEffect(() => {
    if (length) {
      const interval = setInterval(() => {
        setActive(active => (active === length ? 0 : active + 1))
      }, duration * 1000)
      return () => clearInterval(interval)
    }
  }, [duration, length])

  Component.displayName = name
  return <Component active={active} items={items} />
}
