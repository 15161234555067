import PropTypes from "prop-types"

import { VariantProps } from "./VariantProps"

export const LineitemProps = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  selectedOptions: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  price: PropTypes.shape({
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
  }),
  quantity: PropTypes.number,
  link: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape(VariantProps)),
  stockLevel: PropTypes.string,
}
