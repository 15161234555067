import React, { useMemo } from "react"

import { useNavigation } from "../../hooks/useNavigation"

export const withFooterTerms = Component =>
  React.memo(({ name = "FooterTerms" }) => {
    const { terms: items } = useNavigation()

    Component.displayName = name
    return useMemo(() => <Component items={items} />, [items])
  })
