import React, { useState, useEffect, useCallback } from "react"

import { useCore } from "../hooks/useCore"

export const LocationContext = React.createContext(null)

export const LocationProvider = ({ children, config }) => {
  const {
    helpers: { storage },
  } = useCore()

  const { keys } = config?.settings
  const { location: locationService } = config?.services
  const defaultStore = config?.stores[config?.services?.shopify?.defaultShopName]
  const shopify = storage.get(keys?.shopify) || defaultStore

  const [settings, setSettings] = useState({
    country: storage.get(keys?.country) || "",
    location: storage.get(keys?.location) || "",
    locations: Object.values(config?.stores).map(store => store?.siteLocation),
    locating: !storage.get(keys?.country)?.length,
    visitor: !shopify?.siteCountries?.includes(storage.get(keys?.country) || ""),
    shopify,
  })

  const updateLocation = useCallback(
    (countryCode, updateCountry = false) => {
      const shopifyStore = Object.values(config?.stores).filter(store => store?.siteCountries.includes(countryCode))[0] || defaultStore
      const shopifyConfig = { ...shopifyStore, ...config?.services?.shopify }

      setSettings(prevState => ({
        ...prevState,
        country: updateCountry ? countryCode : settings?.country,
        location: countryCode,
        shopify: shopifyConfig,
        visitor: locationService?.forcelocation ? !shopifyStore?.siteCountries?.includes(settings?.country) : false,
        locating: false,
      }))
    },
    [settings, config, defaultStore, locationService]
  )

  useEffect(() => {
    if (settings?.country && settings?.shopify && settings?.location) {
      storage.set(keys?.country, settings?.country)
      storage.set(keys?.location, settings?.location)
      storage.set(keys?.shopify, settings?.shopify)
    } else {
      fetch(locationService?.serviceUrl)
        .then(res => res.json())
        .then(result => updateLocation(result[locationService?.countryFieldKey], true))
    }
  }, [settings, keys, locationService, storage, updateLocation])

  return <LocationContext.Provider value={{ updateLocation, ...settings }}>{children}</LocationContext.Provider>
}

export const withLocation = Component => props => (
  <LocationContext.Consumer>
    {({ updateLocation, ...settings }) => <Component {...props} {...settings} updateLocation={updateLocation} />}
  </LocationContext.Consumer>
)
