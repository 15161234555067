import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Money from "../Money"
import { MoneyProps } from "../../props/MoneyProps"
import { withCartTotals } from "./withCartTotals"

const Row = tw.div`
  w-full flex justify-between mb-0-8
`

const Col = styled.span`
  ${({ right }) => (right ? tw`text-right` : tw`text-left`)}
  ${({ orange }) => (orange ? tw`text-orange` : tw`text-dark`)}
  ${({ bold, total }) => (total ? tw`leading-1.67 text-24 font-medium` : bold ? tw`leading-2.22 text-18 font-medium` : tw`leading-1.56`)}
`

const Divider = tw.div`
  h-0-2 bg-green w-full mt-1-6 mb-3-2
`

const CartTotals = withCartTotals(({ className, layout, subTotal, discountApplied, freeShipping, amountDue, currencyCode, cartItemsQuantity }) => {
  return (
    <div className={className}>
      {layout === `cart` && (
        <Row>
          <Col>{`Subtotal (${cartItemsQuantity} ${cartItemsQuantity > 1 ? `item` : `items`})`}</Col>
          <Col right>
            <Money price={subTotal} />
          </Col>
        </Row>
      )}
      {discountApplied > 0 && (
        <Row>
          <Col>Discount</Col>
          <Col right>
            <Money
              price={{
                amount: discountApplied,
                currencyCode,
              }}
            />
          </Col>
        </Row>
      )}
      {/* {giftcardApplied?.amount > 0 && (
          <Row>
            <Col>Giftcard applied</Col>
            <Col right>
              <Money amount={giftcardApplied.amount} currencyCode={currencyCode} />
            </Col>
          </Row>
        )} */}
      {layout === `cart` && (
        <Row>
          <Col>Estimated delivery costs</Col>
          <Col right orange={freeShipping}>
            {freeShipping ? `FREE` : `Calculate at checkout`}
          </Col>
        </Row>
      )}
      {layout === `cart` && <Divider />}
      <Row>
        <Col bold>Total</Col>
        <Col right bold total={layout === `cart`}>
          <Money price={amountDue} />
        </Col>
      </Row>
    </div>
  )
})

CartTotals.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.oneOf([`cart`, `drawer`]),
  subTotal: PropTypes.shape(MoneyProps),
  discountApplied: PropTypes.number,
  giftcardApplied: PropTypes.shape(MoneyProps),
  amountDue: PropTypes.shape(MoneyProps),
  freeShipping: PropTypes.bool,
  currencyCode: PropTypes.string,
  cartItemsQuantity: PropTypes.number,
}

CartTotals.defaultProps = {
  layout: "cart",
}

export default CartTotals
