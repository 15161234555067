import { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby"

import { useCore } from "./useCore"
import { useShopify } from "./useShopify"
import { useApp } from "./useApp"
import { useCheckout } from "./useCheckout"

export const useCustomerAccessToken = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_ACCESS_TOKEN_CREATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const {
    setCustomer,
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useMutation, useQuery } = useShopify()
  const { updateCustomer } = useCheckout()

  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE)
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, { fetchPolicy: "no-cache", skip: true })

  const getCustomer = useCallback(async () => {
    const customerTokens = storage.get(keys?.customer)

    if (customerTokens?.accessToken) {
      try {
        const {
          data: { customer, customerUserErrors },
        } = await getCustomerQuery({
          customerAccessToken: customerTokens?.accessToken,
        })

        if (!customerUserErrors?.length) setCustomer(customer)
        if (customerUserErrors?.length) storage.remove(keys?.customer)
      } catch (err) {
        console.error(err)
      }
    }
  }, [getCustomerQuery, setCustomer, keys, storage])

  const createAccessToken = useCallback(
    async (email, password) => {
      try {
        const {
          data: {
            customerAccessTokenCreate: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreate({
          variables: { input: { email, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          storage.set(keys?.customer, { accessToken, expiresAt })
          updateCustomer(accessToken)
          getCustomer()
        }

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreate, updateCustomer, getCustomer]
  )

  return { createAccessToken, getCustomer }
}

export const withCustomerAuthentication = () => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    customer,
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { getCustomer } = useCustomerAccessToken()
  const customerTokens = storage.get(keys?.customer)

  if (!customerTokens?.accessToken || new Date(customerTokens?.expiresAt) <= new Date()) {
    storage.remove(keys?.customer)
    if (typeof window !== "undefined") navigate(routes?.LOGIN)
    return false
  }

  if (!customer?.id) getCustomer()
  return true
}

export const useCustomerRegister = () => {
  const {
    graphql: {
      mutations: { CUSTOMER_CREATE },
    },
  } = useCore()
  const { useMutation } = useShopify()
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { createAccessToken } = useCustomerAccessToken()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "", firstName: "", lastName: "", phone: "", acceptsMarketing: true })

  const [customerCreate] = useMutation(CUSTOMER_CREATE)

  const createCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerCreate: { customerUserErrors: errors },
          },
        } = await customerCreate({
          variables: { input: { ...userData } },
        })

        if (errors?.length) {
          setErrors(errors)
          setLoading(false)
        } else {
          const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

          if (!customerUserErrors?.length) {
            if (typeof window !== "undefined") navigate(routes?.HOMEPAGE)
          } else {
            setErrors(customerUserErrors)
            setLoading(false)
          }
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerCreate, createAccessToken, navigate, routes]
  )

  return { createCustomer, data, setData, loading, errors }
}

export const useCustomerLogin = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { createAccessToken } = useCustomerAccessToken()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "" })

  const loginCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      try {
        const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

        if (!customerUserErrors?.length) {
          if (typeof window !== "undefined") navigate(routes?.HOMEPAGE)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, createAccessToken, navigate, routes]
  )

  return { loginCustomer, data, setData, loading, errors }
}

export const useCustomerLogout = () => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    setCustomer,
    config: {
      settings: { keys, routes },
    },
  } = useApp()

  const logoutCustomer = useCallback(() => {
    storage.remove(keys?.customer)
    setCustomer(null)
    if (typeof window !== "undefined") navigate(routes?.LOGIN)
  }, [setCustomer, keys, storage, routes, navigate])

  return { logoutCustomer }
}

export const useCustomerRecover = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_RECOVER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { useMutation } = useShopify()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "" })

  const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  const recoverCustomer = useCallback(
    async email => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerRecover: { customerUserErrors },
          },
        } = await customerRecover({
          variables: { email },
        })

        if (!customerUserErrors?.length) {
          storage.remove(keys?.customer)
          if (typeof window !== "undefined") navigate(routes?.LOGIN)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerRecover, storage, keys, routes]
  )

  return { recoverCustomer, data, setData, loading, errors }
}

export const useCustomerAccount = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_RESET, CUSTOMER_ACTIVATE },
    },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { useMutation } = useShopify()
  const { updateCustomer } = useCheckout()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ password: "" })

  const [customerReset] = useMutation(CUSTOMER_RESET)
  const [customerActivate] = useMutation(CUSTOMER_ACTIVATE)

  const resetCustomer = useCallback(
    async (customerId, resetToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = Buffer.from(`gid://shopify/Customer/${customerId}`, "utf8").toString("base64")

        const {
          data: {
            customerReset: { customerAccessToken, customerUserErrors },
          },
        } = await customerReset({
          variables: { id, input: { resetToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          if (typeof window !== "undefined") navigate(routes?.HOMEPAGE)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerReset, updateCustomer, storage, keys, routes]
  )

  const activateCustomer = useCallback(
    async (customerId, activationToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = Buffer.from(`gid://shopify/Customer/${customerId}`, "utf8").toString("base64")

        const {
          data: {
            customerActivate: { customerAccessToken, customerUserErrors },
          },
        } = await customerActivate({
          variables: { id, input: { activationToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          if (typeof window !== "undefined") navigate(routes?.HOMEPAGE)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerActivate, updateCustomer, storage, keys, routes]
  )

  return { resetCustomer, activateCustomer, data, setData, loading, errors }
}

export const useCustomerOrders = first => {
  const {
    helpers: { storage },
    graphql: {
      queries: { GET_CUSTOMER_ORDERS },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useQuery } = useShopify()
  const { accessToken: customerAccessToken } = storage.get(keys?.customer)

  const { data, loading, error } = useQuery(GET_CUSTOMER_ORDERS, {
    variables: {
      customerAccessToken,
      first,
    },
  })

  const orders = data?.customer?.orders?.edges

  return { orders, loading, error }
}

export const useCustomerOrder = (orderId, key) => {
  const {
    helpers: { encodeBase64 },
    graphql: {
      queries: { GET_ORDER },
    },
  } = useCore()
  const { useQuery } = useShopify()
  const id = encodeBase64(`gid://shopify/Order/${orderId}${key}`)

  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: {
      id,
    },
  })

  const order = data?.node || {}

  return { order, loading, error }
}

export const useCustomerAddress = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_ADDRESS_CREATE, CUSTOMER_ADDRESS_UPDATE, CUSTOMER_ADDRESS_DELETE, CUSTOMER_DEFAULT_ADDRESS_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useMutation, useLazyQuery } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = {
    address1: "",
    address2: "",
    city: "",
    company: "",
    country: "",
    firstName: "",
    lastName: "",
    phone: "",
    province: "",
    zip: "",
  }
  const [address, setAddress] = useState({ ...initialData, id: "", action: "" })
  const [addresses, setAddresses] = useState([])
  const { accessToken: customerAccessToken } = storage.get(keys?.customer)

  const [customerAddressCreate] = useMutation(CUSTOMER_ADDRESS_CREATE)
  const [customerAddressUpdate] = useMutation(CUSTOMER_ADDRESS_UPDATE)
  const [customerAddressDelete] = useMutation(CUSTOMER_ADDRESS_DELETE)
  const [customerDefaultAddressUpdate] = useMutation(CUSTOMER_DEFAULT_ADDRESS_UPDATE)

  const filterData = address =>
    Object.keys(address)
      .filter(key => Object.keys(initialData).includes(key))
      .reduce((obj, key) => {
        obj[key] = address[key]
        return obj
      }, {})

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      customerAccessToken,
    },
  })

  useEffect(() => {
    getAll()
  }, [saving])

  useEffect(() => {
    if (data?.customer)
      setAddresses(
        data?.customer?.addresses?.edges?.map(({ node }) => ({
          ...node,
          default: node?.id === data?.customer?.defaultAddress?.id,
        }))
      )
  }, [data])

  const createAddress = useCallback(
    async address => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressCreate: { customerUserErrors },
          },
        } = await customerAddressCreate({
          variables: { customerAccessToken, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" })
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setAddress, customerAddressCreate, filterData, initialData]
  )

  const updateAddress = useCallback(
    async (id, address) => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressUpdate: { customerAddress, customerUserErrors },
          },
        } = await customerAddressUpdate({
          variables: { customerAccessToken, id, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" })
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setAddress, customerAddressUpdate, filterData, initialData]
  )

  const defaultAddress = useCallback(
    async addressId => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerDefaultAddressUpdate: { customer, customerUserErrors },
          },
        } = await customerDefaultAddressUpdate({
          variables: { addressId, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerDefaultAddressUpdate]
  )

  const deleteAddress = useCallback(
    async id => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressDelete: { deletedCustomerAddressId, customerUserErrors },
          },
        } = await customerAddressDelete({
          variables: { id, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerAddressDelete]
  )

  return { addresses, setAddress, address, createAddress, updateAddress, defaultAddress, deleteAddress, initialData, loading, saving, errors }
}

export const useCustomerDetails = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useLazyQuery, useMutation } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    acceptsMarketing: false,
  }

  const [customerUpdate] = useMutation(CUSTOMER_UPDATE)
  const [customer, setCustomer] = useState(initialData)
  const { accessToken: customerAccessToken } = storage.get(keys?.customer) || { accessToken: "" }

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      customerAccessToken,
    },
  })

  const filterData = data =>
    Object.keys(data)
      .filter(key => Object.keys(initialData).includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key]
        return obj
      }, {})

  useEffect(() => {
    if (customerAccessToken?.length) getAll()
  }, [])

  useEffect(() => {
    if (data?.customer) setCustomer(data?.customer)
  }, [data])

  const updateCustomer = useCallback(
    async customer => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerUpdate: { customerUserErrors },
          },
        } = await customerUpdate({
          variables: { customerAccessToken, customer: filterData(customer) },
        })

        if (!customerUserErrors?.length) {
          setCustomer(prevState => ({
            ...prevState,
            action: false,
          }))
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setCustomer, customerUpdate, filterData]
  )

  return { customer, setCustomer, updateCustomer, loading, saving, errors }
}

export const useShowPassword = () => {
  const [showPassword, setShowPassword] = useState(false)
  return { showPassword, setShowPassword }
}
