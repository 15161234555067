import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import SubscribeFooter from "../Subscribe/SubscribeFooter"
import NavigationFooter from "../Navigation/NavigationFooter"
import FooterSocialLinks from "./FooterSocialLinks"
import FooterCopyright from "./FooterCopyright"
import FooterTerms from "./FooterTerms"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { NavigationProps } from "../../props/NavigationProps"

const Container = styled(StyledContainer)`
  ${tw`pb-3-2 md:pb-4 md:pt-4 md:px-2-4 lg:px-7-2 max-w-xl mx-auto`}
`

const Row = styled.div`
  ${tw`w-full flex justify-between md:flex-row`}
  ${({ reverse }) => (reverse ? tw`flex-col-reverse mt-2-4` : tw`flex-col`)}
`

const Footer = ({ copyright }) => {
  return (
    <>
      <SubscribeFooter />
      <BackgroundContainer background={`light`}>
        <Container width={`full`}>
          <Row>
            <NavigationFooter />
            <FooterSocialLinks />
          </Row>
          <Row reverse>
            <FooterCopyright copyright={copyright} />
            <FooterTerms />
          </Row>
        </Container>
      </BackgroundContainer>
    </>
  )
}

Footer.propTypes = {
  socialLinksNavigation: PropTypes.shape(NavigationProps),
  copyright: PropTypes.string,
}

export default Footer
