import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import Image from "../Image/Image"
import Money from "../Money"

const Wrapper = tw.div`
  w-full flex flex-row items-center
`

const ImageWrapper = tw.div`
  w-1/3 md:w-1/4
`

const InfoWrapper = tw.div`
  w-2/3 md:w-3/4
`

const Title = tw.span`
  inline
`

const Text = styled.p`
  ${tw`inline leading-1.62`}
  ${({ bold }) => (bold ? tw`font-medium` : null)}
`

const Price = styled(Money)`
  ${tw`mt-0-8`}
`

const SearchGlobalResultsProductCard = ({ image, title, subTitle, link, price }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image image={image} ratio={`1/1`} onClick={link ? () => navigate(link) : null} />
      </ImageWrapper>
      <InfoWrapper onClick={link ? () => navigate(link) : null}>
        <Title>
          <Text bold>{title}</Text>
          <Text>&nbsp;{subTitle}</Text>
        </Title>
        <Price amount={price?.amount} currencyCode={price?.currencyCode} />
      </InfoWrapper>
    </Wrapper>
  )
}

SearchGlobalResultsProductCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  link: PropTypes.string,
  price: PropTypes.shape({
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
  }),
}

export default SearchGlobalResultsProductCard
