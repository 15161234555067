import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import ModalsDrawer from "../Modals/ModalsDrawer"
import Accordion from "../Accordion"
import NavigationHeaderSubShoeIcon from "./NavigationHeaderSubShoeIcon"
import { NavigationProps } from "../../props/NavigationProps"

const ContentList = tw.ul`
  flex flex-wrap w-full
`

const LinkList = tw.ul`
  flex flex-col pb-1-2
`
const LinkListItem = styled(Link)`
  ${tw`text-16 py-1-2 hover:text-orange`}
  ${({ viewAll }) => (viewAll ? tw`font-medium` : null)}
  ${({ sale }) => (sale ? tw`font-medium` : null)}
`

const NavigationHeaderMobile = ({ active, setActive, items }) => {
  return (
    <ModalsDrawer active={active} setActive={setActive} noClose>
      {items?.map((item, index) => (
        <Accordion key={index} title={item.title} size={`header`} icon={Boolean(item.contents || item.items)}>
          {item?.contents && (
            <ContentList>
              {item.contents?.map((content, index) => (
                <NavigationHeaderSubShoeIcon key={index} title={content.title} icon={content.icon} url={content.url} />
              ))}
            </ContentList>
          )}
          {item?.items && (
            <LinkList>
              {item.items?.map((linkItem, index) => {
                const viewAll = linkItem.title.toLowerCase().includes(`view all`) ? "true" : undefined
                return (
                  <LinkListItem
                    className={viewAll ? `underlined-black` : null}
                    to={linkItem.url}
                    key={index}
                    viewAll={viewAll}
                    sale={linkItem.title.toLowerCase().includes(`sale`) ? "true" : undefined}
                  >
                    <span>{linkItem.title}</span>
                  </LinkListItem>
                )
              })}
            </LinkList>
          )}
        </Accordion>
      ))}
    </ModalsDrawer>
  )
}

NavigationHeaderMobile.propTypes = {
  active: PropTypes.bool,
  setActive: PropTypes.func,
  navigation: PropTypes.shape(NavigationProps),
}

export default NavigationHeaderMobile
