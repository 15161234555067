import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledButton } from "../Styled/Button"
import { withCartDiscountCode } from "./withCartDiscountCode"

const Wrapper = styled.div`
  ${tw`mb-1-6 relative`}
`

const Text = tw.span`
  text-14 leading-1.71
`

const DiscountInput = tw.input`
  w-full text-16 leading-1 focus:outline-none bg-transparent pb-1-6 border-b border-dark
`

const Apply = styled(StyledButton)`
  ${({ layout }) => (layout === "cart" ? tw`absolute right-0 bottom-0-8 w-8` : tw`w-full mt-1-6`)}
`

const CartDiscountCode = withCartDiscountCode(({ discountCode, handleApply, handleChange, className, layout }) => {
  return (
    <div className={className}>
      <Wrapper>
        <DiscountInput type={`text`} placeholder={`DISCOUNT CODE`} value={discountCode} onChange={handleChange} />
        <Apply
          layout={layout}
          height={`48-40`}
          textSize={`16`}
          colour={layout === `cart` ? `green` : `transparent`}
          border={layout !== `cart`}
          onClick={handleApply}
        >
          Apply
        </Apply>
      </Wrapper>
      <Text>Reward and gift cards can be entered at checkout</Text>
    </div>
  )
})

CartDiscountCode.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.oneOf([`cart`, `drawer`]),
}

CartDiscountCode.defaultProps = {
  layout: "cart",
}

export default CartDiscountCode
