import React, { useMemo } from "react"

import { useNavigation } from "../../hooks/useNavigation"
import { useApp } from "../../hooks/useApp"

export const withNavigationHeader = Component =>
  React.memo(({ name = "NavigationHeader" }) => {
    const { setActiveCart, activeMenu, setActiveMenu, activeSearch, setActiveSearch } = useApp()
    const { header: items } = useNavigation()

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          items={items}
          setActiveCart={setActiveCart}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          activeSearch={activeSearch}
          setActiveSearch={setActiveSearch}
        />
      ),
      [items, setActiveCart, activeMenu, setActiveMenu, activeSearch, setActiveSearch]
    )
  })
