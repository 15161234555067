import React from "react"

import { formatCurrency } from "../../utils/formatCurrency"

export const withPrice = Component => ({ name = "Price", price = {}, compareAtPrice = {}, layout, className }) => {
  const { amount: priceAmount, currencyCode } = price
  const { amount: compareAtPriceAmount } = compareAtPrice

  const onSale = compareAtPriceAmount > priceAmount

  const formattedPrice = formatCurrency(Number(priceAmount), currencyCode, 0)
  const formattedCompareAtPrice = formatCurrency(Number(compareAtPriceAmount), currencyCode, 0)

  Component.displayName = name
  return (
    <Component
      onSale={onSale}
      price={formattedPrice}
      comparedPrice={formattedCompareAtPrice}
      currencyCode={currencyCode}
      layout={layout}
      className={className}
    />
  )
}
