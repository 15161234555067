import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import Globals from "./Globals"
import Main from "./Main"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import SubscribePopup from "../Subscribe/SubscribePopup"
import CartDrawer from "../Cart/CartDrawer"

const Wrapper = tw.div`min-h-full-vh flex flex-col`

export const Layout = ({ children, location, path }) => {
  const copyright = `Bared Trading Pty Ltd, ACN 60606 777 164`

  const topBarNavigation = {
    items: [{ title: `Customer care`, link: `/` }],
  }

  if (path === `/password`) return <Wrapper>{children}</Wrapper>
  return (
    <Wrapper>
      <Globals />
      <Header location={location} title={`Bared Footwear`} topBarNavigation={topBarNavigation} />
      <Main>{children}</Main>
      <Footer copyright={copyright} />
      <SubscribePopup />
      <CartDrawer />
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
