import { useState, useEffect, useCallback } from "react"
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"

import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useShopify = () => {
  const client = useApolloClient()
  const { shop, checkout } = useApp()

  const productNormaliser = product => ({
    ...product,
    images: edgeNormaliser(product?.images)?.map(image => imageNormaliser(image)),
    collections:
      edgeNormaliser(product?.collections)?.map(collection => ({
        ...collection,
        image: imageNormaliser(collection?.image),
      })) || [],
    metafields: edgeNormaliser(product?.metafields),
    variants:
      edgeNormaliser(product?.variants)?.map(variant => ({
        ...variant,
        image: imageNormaliser(variant?.image),
        metafields: edgeNormaliser(variant?.metafields),
        presentmentPrices: edgeNormaliser(variant?.presentmentPrices),
        priceV2: priceNormaliser(variant?.presentmentPrices, "price"),
        compareAtPriceV2: priceNormaliser(variant?.presentmentPrices, "compareAtPrice"),
      })) || [],
  })

  const collectionNormaliser = collection => ({
    ...collection,
    image: imageNormaliser(collection?.image),
    metafields: edgeNormaliser(collection?.metafields),
    products: edgeNormaliser(collection?.products).map(product => productNormaliser(product)),
  })

  const edgeNormaliser = object => object?.edges?.map(({ node }) => node) || object || []

  const imageNormaliser = image => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: image?.originalSrc || image?.src || image?.asset?.url || "",
  })

  const priceNormaliser = (presentmentPrices, field) =>
    Object.assign(
      {},
      ...edgeNormaliser(presentmentPrices)
        ?.filter(item => item[field]?.currencyCode === checkout?.currencyCode || shop?.paymentSettings?.currencyCode)
        .map(item => ({
          ...item[field],
          local: formatMoney(item[field]?.amount, checkout?.currencyCode || shop?.paymentSettings?.currencyCode),
        }))
    )

  const formatMoney = (amount, currency = "AUD") =>
    new Intl.NumberFormat(`en-${shop?.paymentSettings?.countryCode || "AU"}`, {
      style: "currency",
      currency: currency,
    }).format(amount)

  const imageUrl = (src, size) => {
    if (!src || typeof src != "string") return null
    if (typeof src === `string` && !src.includes(`shopify.com`)) return src

    if (typeof size === "number") size = `${size}x${size}`

    const match = src.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i)
    let prefix,
      suffix = null

    if (match) {
      prefix = src.split(match[0])
      suffix = match[0]
    } else {
      return null
    }

    return !size || size === "master" || !src.match(/cdn/i) ? src.replace(/http(s)?:/, "") : `${prefix[0]}_${size}${suffix}`.replace(/http(s)?:/, "")
  }

  return {
    useQuery,
    useLazyQuery,
    useMutation,
    client,
    imageUrl,
    formatMoney,
    edgeNormaliser,
    imageNormaliser,
    priceNormaliser,
    productNormaliser,
    collectionNormaliser,
  }
}

export const useShopifyVariants = ({ useParameter = false, variants = [], location }) => {
  const {
    helpers: { encodeShopifyId, decodeShopifyId, replaceUrl },
  } = useCore()

  const { activeVariant, setActiveVariant } = useApp()

  const params = new URLSearchParams(location?.search)
  const currentVariant = params.get("variant")
  const defaultVariant = (useParameter && variants?.find(({ id }) => id === encodeShopifyId(currentVariant, "ProductVariant"))) || variants[0]

  const [selectedOptions, setOptions] = useState(defaultVariant?.selectedOptions || [])

  const selectVariant = useCallback(
    option => {
      setOptions(selectedOptions.map(selectedOption => (selectedOption.name === option.name ? option : selectedOption)))
    },
    [setOptions, selectedOptions]
  )

  useEffect(() => {
    if (!selectedOptions?.length && selectedOptions?.length !== defaultVariant?.selectedOptions?.length) {
      setOptions(defaultVariant?.selectedOptions)
      setActiveVariant(defaultVariant)
    }
  }, [variants.length])

  useEffect(() => {
    setActiveVariant(
      variants.find(
        ({ selectedOptions: variantOptions }) =>
          variantOptions?.filter(
            variantOption => variantOption.value === selectedOptions.find(selectedOption => selectedOption.name === variantOption.name)?.value
          )?.length === selectedOptions?.length
      ) || null
    )
  }, [selectedOptions])

  useEffect(() => {
    if (useParameter && activeVariant && (currentVariant || activeVariant.id !== defaultVariant.id))
      replaceUrl(`${location.pathname}?variant=${decodeShopifyId(activeVariant.id, "ProductVariant")}`)
  }, [activeVariant])

  return { selectedOptions, activeVariant, selectVariant }
}
