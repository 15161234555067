import React from "react"
import tw from "twin.macro"
import { Link } from "gatsby"

import { NavigationProps } from "../../props/NavigationProps"

const Wrapper = tw.div`z-10 hidden md:block`

const HeaderTopBarMenu = ({ navigation }) => {
  return (
    <Wrapper>
      {navigation.items?.map((item, index) => {
        return (
          <Link key={index} to={item.link}>
            {item.title}
          </Link>
        )
      })}
    </Wrapper>
  )
}

HeaderTopBarMenu.propTypes = NavigationProps

export default HeaderTopBarMenu
