import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const StyledSpan = tw.p`text-12 leading-3.33 text-center`

const FooterCopyright = ({ copyright }) => {
  return copyright ? <StyledSpan>Copyright © {copyright}</StyledSpan> : null
}

FooterCopyright.propTypes = {
  copyright: PropTypes.string,
}

export default FooterCopyright
