import { useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"

export const useNavigation = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const {
    menus: { header, footer, topBar, terms },
  } = useStaticQuery(graphql`
    query NAVIGATION {
      menus: sanitySettingMenus {
        header: _rawHeader(resolveReferences: { maxDepth: 10 })
        footer: _rawFooter(resolveReferences: { maxDepth: 10 })
        topBar: _rawTopBar(resolveReferences: { maxDepth: 10 })
        terms: _rawTerms(resolveReferences: { maxDepth: 10 })
      }
    }
  `)

  const mapItems = items =>
    items?.map(item => {
      const type = item?._type?.replace("navigation", "").toLowerCase()

      if (type === `feat`)
        return {
          type,
          title: item?.title,
          url: mapUrl(item, type),
          items: item?.items?.length > 0 ? mapItems(item.items) : [],
          image: item?.image,
          contents: item?.contents?.length > 0 ? mapItems(item.contents) : [],
        }
      if (type === `collectionwithicon`)
        return {
          type,
          title: item?.title,
          url: mapUrl(item[`collection`], `collection`),
          icon: item?.icon,
        }
      return {
        type,
        title: item?.title,
        url: mapUrl(item[type], type),
        items: item?.items?.length > 0 ? mapItems(item.items) : [],
      }
    })

  const mapUrl = (item, type) =>
    type === "page"
      ? `${
          routes[
            item?._type
              ?.toUpperCase()
              .replace("PAGE", "")
              .replace("ACCOUNT", "")
          ]
        }${item?.slug?.current || ""}`
      : type === "product" || type === "collection"
      ? `${routes[type.toUpperCase()] || "/"}/${item?.shopify?.shopifyHandle || ""}`
      : type === "link"
      ? `${item}`
      : undefined
  return {
    header: useCallback(mapItems(header?.items), [header]),
    footer: useCallback(mapItems(footer?.items), [footer]),
    topBar: useCallback(mapItems(topBar?.items), [topBar]),
    terms: useCallback(mapItems(terms?.items), [terms]),
  }
}
