import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Account from "static/icons/account.svg"
import ArrowDropDown from "static/icons/arrow-dropdown.svg"
import Arrow from "static/icons/arrow.svg"
import Bag from "static/icons/bag.svg"
import BagActive from "static/icons/bag-active.svg"
import Bared from "static/icons/bared.svg"
import Close from "static/icons/close.svg"
import Edit from "static/icons/edit.svg"
import Email from "static/icons/email.svg"
import Hamburger from "static/icons/hamburger-menu.svg"
import Hide from "static/icons/hide.svg"
import OragnicFlower from "static/icons/oragnic-flower.svg"
import Search from "static/icons/search.svg"
import Show from "static/icons/show.svg"
import SpeechBubble from "static/icons/speech-bubble.svg"
import Star from "static/icons/star.svg"
import FilledStar from "static/icons/filled-star.svg"
import TickedBoxActive from "static/icons/ticked-box-active.svg"
import TickedBoxActiveReverse from "static/icons/ticked-box-active-reverse.svg"
import TickedBoxInactive from "static/icons/ticked-box-inactive.svg"
import TickedBoxInactiveReverse from "static/icons/ticked-box-inactive-reverse.svg"
import VintageArrow from "static/icons/vintage-arrow.svg"
import Wishlist from "static/icons/wishlist.svg"
import Wishlisted from "static/icons/wishlisted.svg"
import Plus from "static/icons/plus.svg"
import PlusSmall from "static/icons/plus-small.svg"
import Minus from "static/icons/minus.svg"
import MinusSmall from "static/icons/minus-small.svg"
import Play from "static/icons/play.svg"

import Facebook from "static/icons/facebook.svg"
import Google from "static/icons/google.svg"
import Instagram from "static/icons/instagram.svg"
import Pinterest from "static/icons/pinterest.svg"
import Youtube from "static/icons/youtube.svg"

import AmericanExpress from "static/icons/american-express.svg"
import ApplePay from "static/icons/apple-pay.svg"
import Mastercard from "static/icons/mastercard.svg"
import Paypal from "static/icons/paypal.svg"
import Visa from "static/icons/visa.svg"

import Boat from "static/icons/boat.svg"
import BootsMen from "static/icons/boots-men.svg"
import BootsWomen from "static/icons/boots-women.svg"
import Derby from "static/icons/derby.svg"
import Dress from "static/icons/dress.svg"
import Flats from "static/icons/flats.svg"
import Heels from "static/icons/heels.svg"
import Loafers from "static/icons/loafers.svg"
import Sandals from "static/icons/sandals.svg"
import Sneakers from "static/icons/sneakers.svg"
import Wedges from "static/icons/wedges.svg"

import Logo from "static/logo.svg"

const icons = {
  account: Account,
  arrowDropdown: ArrowDropDown,
  arrow: Arrow,
  bag: Bag,
  bagActive: BagActive,
  bared: Bared,
  close: Close,
  edit: Edit,
  email: Email,
  hamburger: Hamburger,
  hide: Hide,
  oragnicFlower: OragnicFlower,
  search: Search,
  show: Show,
  speechBubble: SpeechBubble,
  star: Star,
  filledStar: FilledStar,
  tickedBoxActive: TickedBoxActive,
  tickedBoxActiveReverse: TickedBoxActiveReverse,
  tickedBoxInactive: TickedBoxInactive,
  tickedBoxInactiveReverse: TickedBoxInactiveReverse,
  vintageArrow: VintageArrow,
  wishlist: Wishlist,
  wishlisted: Wishlisted,
  plus: Plus,
  plusSmall: PlusSmall,
  minus: Minus,
  minusSmall: MinusSmall,
  play: Play,
  facebook: Facebook,
  google: Google,
  instagram: Instagram,
  pinterest: Pinterest,
  youtube: Youtube,
  americanExpress: AmericanExpress,
  applePay: ApplePay,
  mastercard: Mastercard,
  paypal: Paypal,
  visa: Visa,
  boat: Boat,
  bootsMen: BootsMen,
  bootsWomen: BootsWomen,
  derby: Derby,
  dress: Dress,
  flats: Flats,
  heels: Heels,
  loafers: Loafers,
  sandals: Sandals,
  sneakers: Sneakers,
  wedges: Wedges,
  logo: Logo,
}

const styles = {
  size: {
    xxs: tw`w-1-6 h-1-6`,
    xsmall: tw`w-2-4 h-2-4`,
    small: tw`w-2-4 w-2-4 sm:w-3-2 sm:h-3-2`,
    sm: tw`w-4 h-4`,
    medium: tw`w-4 h-4 sm:w-6 h-6`,
    default: tw`w-5 h-5`,
    large: tw`w-4-8 h-4-8 md:w-7-2 md:h-7-2`,
    xlarge: tw`w-5 h-5 sm:w-8 sm:h-8`,
    xxlarge: tw`w-9-6 w-9-6 lg:w-10-4 lg:h-10-4`,
    none: null,
  },
}

const StyledIcon = styled.span`
  ${tw`select-none inline-block `}
  > svg {
    ${tw`fill-current h-full`}
    ${({ size }) => (size ? styles.size[size] : styles.size.default)}
  }
`

const Icon = ({ name, size, className, onClick, width, height }) => {
  return (
    <StyledIcon size={size} className={className} onClick={onClick}>
      <svg viewBox={icons[name].viewBox} id={icons[name].id} width={width} height={height}>
        <use xlinkHref={icons[name].url} />
      </svg>
    </StyledIcon>
  )
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  size: PropTypes.oneOf(Object.keys(styles.size)),
  className: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
}

Icon.defaultProps = {
  size: "default",
  className: "",
  onClick: () => {},
}

export default Icon
