import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import NavigationHeaderSub from "./NavigationHeaderSub"
import NavigationHeaderMobile from "./NavigationHeaderMobile"
import Icon from "../Icon"
import Search from "../Search/Search"
import { NavigationProps } from "../../props/NavigationProps"
import { withNavigationHeader } from "./withNavigationHeader"

const Desktop = tw.div`
  hidden lg:flex flex-row items-center
`

const Mobile = tw.div`
  flex flex-row items-center lg:hidden
`

const Button = tw.button`
  focus:outline-none p-0-8
`

const MenuList = tw.ul`
  flex flex-row items-center w-4/9
`

const MenuItem = tw.li``

const Title = tw.div`
  text-16 leading-2.25 p-2-4 text-dark group-hover:text-orange transition-all duration cursor-pointer
`
const StyledSearch = styled(Search)`
  ${tw`absolute top-0 bottom-0 left-9-6 sm:left-11-2 right-1-6 bg-lightest flex items-center md:max-w-42`}
`

const NavigationHeader = withNavigationHeader(({ items, setActiveCart, activeMenu, setActiveMenu, activeSearch, setActiveSearch }) => {
  return (
    <MenuList>
      <Desktop>
        {items?.map((item, index) => (
          <MenuItem key={index} className={`group`}>
            <Title as={item.url ? Link : `div`} to={item.url}>
              {item.title}
            </Title>
            {item.items && item.items.length > 0 ? <NavigationHeaderSub subNavigation={item} /> : null}
          </MenuItem>
        ))}
      </Desktop>
      <Mobile>
        <Button
          onClick={() => {
            setActiveMenu(!activeMenu)
            setActiveCart(false)
            setActiveSearch(false)
          }}
        >
          {!activeMenu ? <Icon name={`hamburger`} size={`small`} /> : <Icon name={`close`} size={`small`} />}
        </Button>
        <Button
          onClick={() => {
            setActiveSearch(!activeSearch)
            setActiveMenu(false)
            setActiveCart(false)
          }}
        >
          <Icon name={`search`} size={`small`} />
        </Button>
        {activeSearch && <StyledSearch activeSearch={activeSearch} setActiveSearch={setActiveSearch} />}
        <NavigationHeaderMobile active={activeMenu} setActive={setActiveMenu} items={items} />
      </Mobile>
    </MenuList>
  )
})

NavigationHeader.propTypes = {
  navigation: PropTypes.shape(NavigationProps),
  setActiveCart: PropTypes.func,
  activeMenu: PropTypes.bool,
  setActiveMenu: PropTypes.func,
  activeSearch: PropTypes.bool,
  setActiveSearch: PropTypes.func,
}

export default NavigationHeader
