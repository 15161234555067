import React, { useState } from "react"

import { useFunctions } from "../../hooks/useFunctions"
import { useSettings } from "../../hooks/useSettings"
import { useCore } from "../../hooks/useCore"

export const withSubscribeFooter = Component => ({ name = "Subscribe" }) => {
  const {
    helpers: { sanityContent },
  } = useCore()
  const { subscribeFooter } = useSettings()
  const { customerSubscribe, errors } = useFunctions()
  const [data, setData] = useState({ email: "" })
  const [tags, setTags] = useState([])
  const [success, setSuccess] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    const { customer } = await customerSubscribe(data?.email, tags)
    if (customer?.id) {
      setSuccess(true)
    }
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const toogleTag = tag => {
    const index = tags.indexOf(tag)
    index === -1 ? setTags([...tags, tag]) : setTags(tags.filter(a => a !== tag))
  }

  const options = subscribeFooter?.options
  const title = subscribeFooter?.footerTitle
  const description = sanityContent(subscribeFooter?.footerContent)
  const successMessage = sanityContent(subscribeFooter?.footerSuccessMessage)

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      errors={errors}
      options={options}
      title={title}
      description={description}
      successMessage={successMessage}
      tags={tags}
      toogleTag={toogleTag}
      success={success}
    />
  )
}
