import React from "react"

import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { useCart } from "../../hooks/useCart"
import { useShopify } from "../../hooks/useShopify"

export const withCartLineitemCard = Component => ({ name = "CartLineitemCard", lineitem, className, layout }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const {
    graphql: {
      queries: { GET_PRODUCT },
    },
  } = useCore()
  const { useQuery, productNormaliser } = useShopify()
  const { removeFromCart, updateQuantity, updateVariant, loading: cartLoading } = useCart()

  const { data } =
    typeof window !== `undefined`
      ? useQuery(GET_PRODUCT, {
          fetchPolicy: `cache-and-network`,
          variables: {
            id: lineitem.variant.product.id,
            handle: lineitem.variant.product.handle,
            parentQuery: `tag:'parent:${lineitem.variant.product.handle}'`,
            firstCollections: 1,
            firstImages: 1,
            firstMetafields: 1,
            firstVariants: 20,
          },
        })
      : { data: false }

  const mappedLineitem = {
    id: lineitem.variant.id,
    title: lineitem.title.split(" ")[0],
    subTitle: lineitem.title.split(" ")[1],
    quantity: lineitem.quantity,
    price: lineitem.variant.priceV2,
    compareAtPrice: lineitem.variant.compareAtPriceV2,
    image: lineitem.variant.image.originalSrc,
    link: `${routes.PRODUCT}/${lineitem.variant.product.handle}`,
    selectedOptions: lineitem.variant.selectedOptions[0],
  }

  const { variants } = productNormaliser(data?.product)

  const options = variants.map(variant => {
    return {
      label: variant.availableForSale ? variant.selectedOptions[0].value : `${variant.selectedOptions[0].value} - Sold out`,
      value: variant.selectedOptions[0].value,
    }
  })

  const handleSelect = ({ target: { value } }) => {
    const newSelectedVariant = variants.find(variant => variant.selectedOptions[0].value === value)
    updateVariant(mappedLineitem.id, newSelectedVariant.id)
  }

  Component.displayName = name
  return (
    <Component
      lineitem={mappedLineitem}
      className={className}
      layout={layout}
      options={options}
      handleSelect={handleSelect}
      removeFromCart={removeFromCart}
      updateQuantity={updateQuantity}
      cartLoading={cartLoading}
    />
  )
}
