import PropTypes from "prop-types"

export const GatsbyImageSourceProps = {
  aspectRatio: PropTypes.number,
  src: PropTypes.string,
  srcWebp: PropTypes.string,
  srcSet: PropTypes.string,
  srcSetWebp: PropTypes.string,
  sizes: PropTypes.string,
}

export const GatsbyImageProps = {
  alt: PropTypes.string,
  onClick: PropTypes.func,
  ratio: PropTypes.string,
  image: PropTypes.shape(GatsbyImageSourceProps),
  fullHeight: PropTypes.bool,
}

export const ShopifyImageSourceProps = {
  src: PropTypes.string,
}

export const ShopifyImageProps = {
  alt: PropTypes.string,
  cover: PropTypes.bool,
  onClick: PropTypes.func,
  ratio: PropTypes.string,
  image: PropTypes.string,
  fullWidth: PropTypes.bool,
  hoverSrc: PropTypes.string,
}

export const ImageProps = {
  alt: PropTypes.string,
  cover: PropTypes.bool,
  onClick: PropTypes.func,
  ratio: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.shape(GatsbyImageSourceProps), PropTypes.string]),
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hoverImage: PropTypes.string,
}
