import React, { useCallback, useState, useMemo } from "react"
import PropTypes from "prop-types"

const LayoutContext = React.createContext({})

export const LayoutProvider = ({ children }) => {
  // const [accountPath, setAccountPath] = useState(``)
  const [activeCart, setActiveCart] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const [activeMenu, setActiveMenu] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(`AUD`)
  // const [currencyRates, setCurrencyRates] = useState(null)
  // const [terms, setTerms] = useState(``)
  // const [invertedTheme, setInvertedTheme] = useState(false)
  // const [transparentTheme, setTransparentTheme] = useState(false)

  // const updateAccountPath = useCallback(setAccountPath, [])
  const updateActiveCart = useCallback(setActiveCart, [])
  const updateActiveSearch = useCallback(setActiveSearch, [])
  const updateActiveMenu = useCallback(setActiveMenu, [])
  const updateSelectedCurrency = useCallback(setSelectedCurrency, [])
  // const updateCurrencyRates = useCallback(setCurrencyRates, [])
  // const updateTerms = useCallback(setTerms, [])
  // const updateInvertedTheme = useCallback(setInvertedTheme, [])
  // const updateTransparentTheme = useCallback(setTransparentTheme, [])

  const providerValue = useMemo(
    () => ({
      // accountPath,
      // setAccountPath: updateAccountPath,
      activeCart,
      setActiveCart: updateActiveCart,
      activeSearch,
      setActiveSearch: updateActiveSearch,
      activeMenu,
      setActiveMenu: updateActiveMenu,
      selectedCurrency,
      setSelectedCurrency: updateSelectedCurrency,
      // currencyRates,
      // setCurrencyRates: updateCurrencyRates,
      // terms,
      // setTerms: updateTerms,
      // invertedTheme,
      // setInvertedTheme: updateInvertedTheme,
      // transparentTheme,
      // setTransparentTheme: updateTransparentTheme,
    }),
    [
      // accountPath,
      // updateAccountPath,
      activeCart,
      updateActiveCart,
      activeSearch,
      updateActiveSearch,
      activeMenu,
      updateActiveMenu,
      selectedCurrency,
      updateSelectedCurrency,
      // currencyRates,
      // updateCurrencyRates,
      // terms,
      // updateTerms,
      // invertedTheme,
      // updateInvertedTheme,
      // transparentTheme,
      // updateTransparentTheme,
    ],
  )

  return <LayoutContext.Provider value={providerValue}>{children}</LayoutContext.Provider>
}

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutContext
