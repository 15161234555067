import React from "react"

import { useApp } from "../../../hooks/useApp"

export const withWishlistWidget = Component => ({ name = "WishlistWidget" }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const wishlistUrl = routes?.WISHLIST

  Component.displayName = name
  return <Component wishlistUrl={wishlistUrl} />
}
