import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import Icon from "../Icon"
import Search from "../Search/Search"
import { CartWidget } from "../Cart/CartWidget"
import { WishlistWidget } from "../Customer/Wishlist/WishlistWidget"

const Wrapper = tw.div`w-4/9 flex flex-row items-center justify-end`

const StyledIcon = styled(Icon)`
  ${tw`p-1-2 md:p-1-6 hover:text-orange transition-all duration`}
`

const StyledSearch = styled(Search)`
  ${tw`hidden flex-1 lg:flex items-center h-full pr-1-6`}
`

const StyledAccount = styled(Link)`
  ${tw`block`}
`

const HeaderAccount = ({ activeCart, setActiveCart, setActiveMenu, activeSearch, setActiveSearch }) => {
  return (
    <Wrapper>
      <StyledSearch
        activeSearch={activeSearch}
        setActiveSearch={setActiveSearch}
        onClick={() => {
          setActiveSearch(true)
          setActiveCart(false)
          setActiveMenu(false)
        }}
      />
      <WishlistWidget />
      <StyledAccount to={"/account"}>
        <StyledIcon name={`account`} size={`small`} />
      </StyledAccount>
      <CartWidget />
    </Wrapper>
  )
}

HeaderAccount.propTypes = {
  activeCart: PropTypes.bool,
  setActiveCart: PropTypes.func,
  setActiveMenu: PropTypes.func,
  activeSearch: PropTypes.bool,
  setActiveSearch: PropTypes.func,
}

export default HeaderAccount
