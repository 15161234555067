import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Image from "./Image/Image"
import { GatsbyImageSourceProps } from "../props/ImageProps"

const Wrapper = styled.div`
  ${tw`flex w-full flex-col`}
  ${({ reverse }) => (reverse ? tw`md:flex-row-reverse` : tw`md:flex-row`)}
`

const ImageWrapper = styled.div`
  ${tw`w-full`}
  ${({ mobileHidden }) => (mobileHidden ? tw`hidden md:block` : null)}
  ${({ oneThird }) => (oneThird ? tw`md:w-2/5` : tw`md:w-1/2 flex-1`)}
`
const ContentWrapper = styled.div`
  ${tw`w-full `}
  ${({ oneThird }) => (oneThird ? tw`md:w-3/5` : tw`md:w-1/2 flex-1`)}
`

const ImageContent = ({ image, children, reverse, mobileHidden, oneThird }) => {
  return (
    <Wrapper reverse={reverse}>
      {image && (
        <ImageWrapper mobileHidden={mobileHidden} oneThird={oneThird}>
          <Image image={image} fullHeight />
        </ImageWrapper>
      )}
      <ContentWrapper oneThird={oneThird}>{children}</ContentWrapper>
    </Wrapper>
  )
}

ImageContent.propTypes = {
  image: PropTypes.shape(GatsbyImageSourceProps),
  reverse: PropTypes.bool,
  mobileHidden: PropTypes.bool,
  children: PropTypes.node,
  oneThird: PropTypes.bool,
}

export default ImageContent
