// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-account-jsx": () => import("./../src/pages/account/account.jsx" /* webpackChunkName: "component---src-pages-account-account-jsx" */),
  "component---src-pages-account-forgot-jsx": () => import("./../src/pages/account/forgot.jsx" /* webpackChunkName: "component---src-pages-account-forgot-jsx" */),
  "component---src-pages-account-login-jsx": () => import("./../src/pages/account/login.jsx" /* webpackChunkName: "component---src-pages-account-login-jsx" */),
  "component---src-pages-account-register-jsx": () => import("./../src/pages/account/register.jsx" /* webpackChunkName: "component---src-pages-account-register-jsx" */),
  "component---src-pages-account-reset-jsx": () => import("./../src/pages/account/reset.jsx" /* webpackChunkName: "component---src-pages-account-reset-jsx" */),
  "component---src-pages-account-wishlist-jsx": () => import("./../src/pages/account/wishlist.jsx" /* webpackChunkName: "component---src-pages-account-wishlist-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-collection-jsx": () => import("./../src/pages/collection.jsx" /* webpackChunkName: "component---src-pages-collection-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-password-jsx": () => import("./../src/pages/password.jsx" /* webpackChunkName: "component---src-pages-password-jsx" */),
  "component---src-pages-search-jsx": () => import("./../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-stores-jsx": () => import("./../src/pages/stores.jsx" /* webpackChunkName: "component---src-pages-stores-jsx" */),
  "component---src-templates-article-tsx": () => import("./../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-flexible-tsx": () => import("./../src/templates/flexible.tsx" /* webpackChunkName: "component---src-templates-flexible-tsx" */),
  "component---src-templates-generic-tsx": () => import("./../src/templates/generic.tsx" /* webpackChunkName: "component---src-templates-generic-tsx" */),
  "component---src-templates-product-tsx": () => import("./../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-store-tsx": () => import("./../src/templates/store.tsx" /* webpackChunkName: "component---src-templates-store-tsx" */)
}

