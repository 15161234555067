import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Icon from "../Icon"
import { StyledInput } from "../Styled/FormElements"
import { scrollHidden } from "../../utils/domUtils"
import SearchGlobalResults from "./SearchGlobalResults"

const Wrapper = styled.div`
  ${tw`relative`}
`

const InputWrapper = tw.div`
  relative flex-1
`

const IconWrapper = tw.div`
  absolute right-0 bottom-0 hidden lg:block focus:outline-none
`

const search = React.createRef()

const Search = ({ activeSearch, setActiveSearch, className, onClick }) => {
  useEffect(() => {
    // if (search?.current) {
    //   setTimeout(() => {
    //     if (activeSearch) {
    //       search.current.focus()
    //     } else {
    //       search.current.blur()
    //     }
    //   }, 300)
    // }

    scrollHidden(activeSearch)
  }, [activeSearch])

  return (
    <Wrapper className={className}>
      <InputWrapper onClick={onClick}>
        <StyledInput placeholder={`I am looking for...`} ref={search} />
        <IconWrapper>
          <Icon name={`search`} size={`small`} />
        </IconWrapper>
      </InputWrapper>
      {activeSearch && <SearchGlobalResults setActiveSearch={setActiveSearch} />}
    </Wrapper>
  )
}

Search.propTypes = {
  activeSearch: PropTypes.bool,
  setActiveSearch: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Search

//TODO - setup search and filter package when start work with Search and CLP
