import React from "react"

import { imageUrl } from "../../utils/shopifyUtils"
import { ImageRatio, ImageWrapper, StyledImage } from "../Styled/Image"
import { ShopifyImageProps } from "../../props/ImageProps"

const SIZES = [1, 500, 1000, 1500, 2000]

const buildSrcSets = src =>
  src && typeof src === `string` && src.includes(`shopify.com`)
    ? SIZES.map(size => `${imageUrl(src, size)} ${size}w`).join(`,`)
    : null

const ShopifyImage = ({ alt, cover, onClick, ratio, src, fullWidth, hoverSrc }) => {
  return ratio ? (
    <ImageWrapper fullWidth={fullWidth} onClick={onClick}>
      <ImageRatio ratio={ratio} />
      <StyledImage
        alt={alt}
        cover
        src={imageUrl(src, SIZES[0])}
        srcSet={buildSrcSets(src)}
        draggable={false}
      />
      {hoverSrc && (
        <StyledImage
          alt={alt}
          cover
          hover
          src={imageUrl(hoverSrc, SIZES[0])}
          srcSet={buildSrcSets(hoverSrc)}
          draggable={false}
        />
      )}
    </ImageWrapper>
  ) : (
    <StyledImage
      alt={alt}
      cover={cover}
      onClick={onClick}
      src={imageUrl(src, SIZES[0])}
      srcSet={buildSrcSets(src)}
      draggable={false}
    />
  )
}

ShopifyImage.propTypes = ShopifyImageProps

export default ShopifyImage
