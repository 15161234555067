import PropTypes from "prop-types"

export const VariantProps = {
  available: PropTypes.bool,
  id: PropTypes.string,
  price: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      currencyCode: PropTypes.string,
    }),
  ),
  comparedPrice: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      currencyCode: PropTypes.string,
    }),
  ),
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}
