import React from "react"

import { useCheckout } from "../../hooks/useCheckout"
import { usePage } from "../../hooks/usePage"

export const withCartTotals = Component => ({ name = "CartTotals", className, layout }) => {
  const {
    checkout: { totalPriceV2, paymentDueV2, lineItemsSubtotalPrice },
    cartItemsQuantity,
  } = useCheckout()

  const {
    cart: { freeShippingThreshold },
  } = usePage()

  const freeShipping = parseFloat(totalPriceV2?.amount) - freeShippingThreshold > 0

  const currencyCode = totalPriceV2?.currencyCode

  const discountApplied = parseFloat(lineItemsSubtotalPrice?.amount) - parseFloat(totalPriceV2?.amount)

  Component.displayName = name
  return (
    <Component
      className={className}
      layout={layout}
      amountDue={paymentDueV2}
      subTotal={lineItemsSubtotalPrice}
      freeShipping={freeShipping}
      currencyCode={currencyCode}
      discountApplied={discountApplied.toString()}
      cartItemsQuantity={cartItemsQuantity}
    />
  )
}
