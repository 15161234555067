import { useStaticQuery, graphql } from "gatsby"

export const useSettings = () =>
  useStaticQuery(graphql`
    query SANITY_SETTINGS {
      announcements: sanitySettingAnnouncements {
        duration
        announcements {
          title
          link {
            link
            external
          }
        }
      }
      social: sanitySettingSocial {
        instagramAccessToken
        instagramClientID
        instagramUsername
        channels {
          title
          url
        }
      }
      scripts: sanitySettingScripts {
        trackingHeader {
          code
        }
        trackingBody {
          code
        }
        trackingFooter {
          code
        }
      }
      preferences: sanitySettingPreferences {
        url
        title
        description
        logo {
          asset {
            url
          }
        }
        location
        language
        author
      }
      languages: sanitySettingLanguages {
        languages {
          language
          region
          url
        }
      }
      appearance: sanitySettingAppearance {
        themeDisplay
        themeColour {
          hex
        }
        themeBgColour {
          hex
        }
      }
      maintenance: sanitySettingMaintenance {
        title
        password
        enabled
        backgroundImage {
          asset {
            url
          }
        }
      }
      forms: allSanitySettingForms {
        edges {
          node {
            id: _id
            title
            slug {
              current
            }
            subject
            types {
              title
              recipients
              options
            }
          }
        }
      }
      swatches: allSanitySettingSwatches {
        edges {
          node {
            id: _id
            name
            slug {
              current
            }
            linked
            colour {
              hex
            }
            image {
              asset {
                url
              }
            }
          }
        }
      }
      tracking: sanitySettingTracking {
        googleTagManagerId
        googleSiteVerify
        googleAnalyticsId
        facebookPixelId
        facebookAppId
      }
      sizes: allSanitySettingSizeGuide {
        edges {
          node {
            women {
              us_au
              eu
            }
            men {
              us
              uk_au
              eu
            }
          }
        }
      }
      subscribeFooter: sanitySettingSubscribe {
        options {
          title
          tag
        }
        footerTitle
        footerContent: _rawFooterContent(resolveReferences: { maxDepth: 10 })
        footerSuccessMessage: _rawFooterSuccessMessage(resolveReferences: { maxDepth: 10 })
      }
      subscribePopup: sanitySettingSubscribe {
        options {
          title
          tag
        }
        popupTitle
        popupContent: _rawPopupContent(resolveReferences: { maxDepth: 10 })
        popupSuccessTitle
        popupSuccessMessage: _rawPopupSuccessMessage(resolveReferences: { maxDepth: 10 })
        popupTerms: _rawPopupTerms(resolveReferences: { maxDepth: 10 })
        image {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      allStores: allSanityStore {
        edges {
          node {
            ...GatsbyStoreFragment
          }
        }
      }
    }
  `)
