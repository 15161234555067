import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withSubscribeFooter } from "./withSubscribeFooter"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import Icon from "../Icon"

const Container = styled(StyledContainer)`
  ${tw`flex flex-col items-center justify-center pt-6-4 pb-8-8 text-lightest`}
`

const Title = tw.h3`
  font-display text-38 leading-1.21 md:text-56 md:leading-1.36 mb-1-6
`

const Description = styled.div`
  ${tw`mb-4 md:mb-2-4 mx-3-2 sm:mx-0 inline-block text-center`}
  .rte {
    p {
      ${tw`text-16 md:text-20 leading-1.75 md:leading-2`}
    }
  }
`

const OptionsWrapper = tw.div`
  flex items-center flex-col md:flex-row mb-2-4
`

const OptionsTitle = tw.p`
  text-16 leading-1 mr-0-8 md:mr-0 pb-0-2 mb-1-2 md:mb-0
`

const Options = tw.div`
  flex flex-row text-16 leading-1
`

const Option = tw.button`
  focus:outline-none mx-0-8 flex flex-row items-center
`

const OptionTitle = tw.p`
  pl-0-4 text-16 leading-1 pb-0-2
`

const Email = tw.form`
  h-5-6 flex items-center justify-between border border-light px-2-4 w-full max-w-60
`

const Input = styled.input`
  ${tw`flex-1 bg-green focus:outline-none`} ::placeholder {
    ${tw`text-lightest`}
  }
`

const Submit = tw.button`
  focus:outline-none
`

const Error = tw.div`
  text-12 mb-1-6 text-orange
`

const SuccessMessage = tw.div`
  max-w-60 w-full h-5-6 bg-lightest text-dark flex items-center justify-center text-14
`

const SubmitIcon = styled(Icon)`
  ${tw`text-lightest hover:transform hover:translate-x-0-4 transition-transform duration-slow`}
`

const FooterSubscribe = withSubscribeFooter(
  ({ data, handleChange, handleSubmit, errors, options, title, description, successMessage, tags, toogleTag, success }) => {
    return (
      <BackgroundContainer background={`green`}>
        <Container>
          <Title>{title}</Title>
          <Description>
            <div className={`rte`}>{description}</div>
          </Description>
          <OptionsWrapper>
            <OptionsTitle>I am interested in</OptionsTitle>
            <Options>
              {options.map((option, index) => (
                <Option key={`${option.title}-${index}`} onClick={() => toogleTag(option.tag)}>
                  {tags.indexOf(option.tag) === -1 ? (
                    <Icon name={`tickedBoxInactiveReverse`} size={`xxs`} />
                  ) : (
                    <Icon name={`tickedBoxActiveReverse`} size={`xxs`} />
                  )}

                  <OptionTitle>{option.title}</OptionTitle>
                </Option>
              ))}
            </Options>
          </OptionsWrapper>
          {!success ? (
            <>
              <Email onSubmit={handleSubmit}>
                <Input name={`email`} tpye={`email`} placeholder={`Email`} value={data?.email} onChange={handleChange} required />
                <Submit type={`submit`}>
                  <SubmitIcon name={`vintageArrow`} size={`small`} />
                </Submit>
              </Email>
              {errors?.length > 0 &&
                errors?.filter(error => error?.field?.length && error?.field[1] === "email").map(error => <Error>{error?.message}</Error>)}
            </>
          ) : (
            <SuccessMessage>{successMessage.message}</SuccessMessage>
          )}
        </Container>
      </BackgroundContainer>
    )
  }
)

export default FooterSubscribe
