import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { NavigationProps } from "../../props/NavigationProps"
import { withFooterTerms } from "./withFooterTerms"

const List = tw.ul`flex justify-center`

const ListItem = styled(Link)`
  ${tw`text-12 leading-3.33 inline px-1-6 sm:px-0 hover:text-orange transition-all duration`}
`
const Divider = tw.span`
  px-0-8 hidden sm:inline
`
const FooterTerms = withFooterTerms(({ items }) => {
  return (
    <List>
      {items?.map((item, index) => (
        <ListItem key={index} to={item.url}>
          {index !== 0 && <Divider>|</Divider>}
          {item.title}
        </ListItem>
      ))}
    </List>
  )
})

FooterTerms.propTypes = NavigationProps

export default FooterTerms
