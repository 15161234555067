import React from "react"

import "./static/styles/index.css"

import { LocationProvider } from "./src/providers/location"
import { FirebaseProvider } from "./src/providers/firebase"
import { ShopifyProvider } from "./src/providers/shopify"
import { AppProvider } from "./src/providers/app"
import { SearchProvider } from "./src/providers/search"
import { Layout } from "./src/components/Layout/Layout"

import config from "./config.default.js"

export const wrapRootElement = ({ element }) => (
  <LocationProvider config={config}>
    <ShopifyProvider
      config={{
        shopName: config?.services?.shopify?.defaultShopName,
        apiVersion: config?.services?.shopify?.apiVersion,
        accessToken: config?.stores[config?.services?.shopify?.defaultShopName]?.accessToken,
      }}
    >
      <FirebaseProvider config={config?.services?.firebase}>
        <AppProvider config={config}>
          <SearchProvider
            config={{
              ...config?.services?.reactify,
              searchIndex: config?.stores[config?.services?.shopify?.defaultShopName]?.searchIndex,
            }}
          >
            {element}
          </SearchProvider>
        </AppProvider>
      </FirebaseProvider>
    </ShopifyProvider>
  </LocationProvider>
)

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props} settings={config.settings}>
    {element}
  </Layout>
)
