import React, { useMemo } from "react"

import { useApp } from "../../hooks/useApp"
import { useCheckout } from "../../hooks/useCheckout"
import { useState } from "react"

export const withCurrency = Component =>
  React.memo(({ name = "Currency" }) => {
    const { shop } = useApp()
    const { checkout, updateCurrency } = useCheckout()
    const [selectorActive, setSelectorActive] = useState(false)

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          currency={checkout?.currencyCode}
          currencies={shop?.paymentSettings?.enabledPresentmentCurrencies}
          updateCurrency={updateCurrency}
          selectorActive={selectorActive}
          setSelectorActive={setSelectorActive}
        />
      ),
      [shop, checkout, selectorActive, updateCurrency]
    )
  })
