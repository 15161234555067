import React from "react"
import app from "firebase/app"

import "firebase/functions"
import "firebase/auth"
import "firebase/firestore"

export const FirebaseContext = React.createContext({ firebase: app })

export const FirebaseProvider = ({ children, config }) => {
  if (typeof window !== "undefined") {
    const firebase = app.apps.length
      ? app[0]
      : app.initializeApp({
          apiKey: config.apiKey,
          authDomain: config.authDomain,
          databaseURL: config.databaseURL,
          projectId: config.projectId,
        })
    return <FirebaseContext.Provider value={{ firebase }}>{children}</FirebaseContext.Provider>
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>{({ firebase }) => <Component {...props} firebase={firebase} />}</FirebaseContext.Consumer>
)
