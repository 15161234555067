import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withAnnouncement } from "./withAnnouncement"

const Wrapper = tw.div`absolute w-full left-0 top-0 text-center h-3 md:h-3-6`

const Item = styled.div`
  ${tw`absolute inset-0 flex items-center justify-center text-center transform transition-all duration`}
  ${({ active }) => (active ? tw`opacity-100 visible translate-y-0` : tw`opacity-0 invisible -translate-y-6`)}
`

const Announcement = withAnnouncement(({ items, active }) => {
  return (
    <Wrapper>
      {items.map(({ title, link }, index) => (
        <Item
          key={title.toString()}
          active={active === index ? "true" : undefined}
          as={link?.link && Link}
          title={link?.link && link?.title}
          to={link?.link}
        >
          {title}
        </Item>
      ))}
    </Wrapper>
  )
})

Announcement.propTypes = {
  message: PropTypes.string,
  link: PropTypes.string,
}

export default Announcement
