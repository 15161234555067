import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withCartWidget } from "./withCartWidget"
import Icon from "../Icon"

const StyledCart = tw.button`
  focus:outline-none relative
`

const StyledIcon = styled(Icon)`
  ${tw`p-1-2 md:p-1-6 group-hover:text-orange transition-all duration`}
`

const Quantity = styled.p`
  ${tw`text-12 absolute top-1/2 left-1/2 text-lightest transition-all duration`}
  transform: translate(-50%, -30%);
`

export const CartWidget = withCartWidget(({ quantity, setActiveCart, setActiveMenu, setActiveSearch }) => (
  <StyledCart
    className={`group`}
    onClick={() => {
      setActiveCart(prevState => !prevState)
      setActiveMenu(false)
      setActiveSearch(false)
    }}
  >
    <StyledIcon name={quantity > 0 ? `bagActive` : `bag`} size={`xsmall`} />
    <Quantity>{quantity || "0"}</Quantity>
  </StyledCart>
))
