import { useState, useCallback } from "react"
import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useSettings } from "./useSettings"

export const useAnnouncement = () => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { announcements } = useSettings()
  const [show, setShow] = useState(storage.get(keys?.announcement) !== "hidden")

  const items = announcements?.announcements || []

  const hide = useCallback(() => {
    storage.set(keys?.announcement, "hidden")
    setShow(false)
  }, [setShow])

  return {
    items,
    show,
    hide,
  }
}
