import { useState, useCallback } from "react"
import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useShopify } from "./useShopify"
import { useCheckout } from "./useCheckout"

export const useCart = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CHECKOUT_LINE_ITEMS_REPLACE },
    },
  } = useCore()
  const {
    checkout,
    config: {
      settings: { keys },
    },
  } = useApp()
  const { setCheckout } = useCheckout()
  const { useMutation } = useShopify()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const checkoutId = checkout?.id || storage.get(keys?.checkout)

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)

  const addToCart = useCallback(
    async (variantId, quantity = 1, customAttributes = []) => {
      setLoading(true)
      let alreadyInCart = false

      const lineItems =
        checkout?.lineItems?.map(lineItem => {
          if (lineItem?.variant?.id === variantId) {
            alreadyInCart = true
            return {
              customAttributes: [
                ...lineItem?.customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })),
                ...(customAttributes || []),
              ],
              quantity: lineItem?.quantity + quantity,
              variantId,
            }
          }
          return {
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            variantId: lineItem?.variant?.id,
          }
        }) || []

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems: [...(alreadyInCart ? lineItems : [...lineItems, { quantity, variantId, customAttributes }])],
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout]
  )

  const removeFromCart = useCallback(
    async variantId => {
      setLoading(true)

      const lineItems = checkout?.lineItems
        .filter(lineItem => lineItem.variant.id !== variantId)
        .map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout]
  )

  const updateQuantity = useCallback(
    async (variantId, quantity) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.variant.id === variantId ? quantity : lineItem.quantity,
        variantId: lineItem.variant.id,
      }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
      }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout]
  )

  const updateItem = useCallback(
    async (variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...lineItem?.customAttributes?.map(({ key, value }) => ({
                      key,
                      value,
                    })),
                    ...Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: {
        checkoutId,
        lineItems: [],
      },
    })

    if (errors?.length) setErrors(errors)
    if (data) setCheckout(data?.checkout)
    setLoading(false)
  }, [lineItemsReplace, setErrors, setCheckout, setLoading])

  return {
    addToCart,
    removeFromCart,
    updateQuantity,
    updateVariant,
    updateItem,
    clearCart,
    loading,
    errors,
  }
}
