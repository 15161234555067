import React from "react"
import Img from "gatsby-image"

import { GatsbyImageProps } from "../../props/ImageProps"

const GatsbyImage = ({ alt, onClick, ratio, image, fullHeight }) => {
  return (
    <Img
      alt={alt}
      onClick={onClick}
      fluid={ratio ? { ...image, aspectRatio: ratio.split("/")[0] / ratio.split("/")[1] } : image}
      style={fullHeight ? { height: "100%" } : null}
    />
  )
}

GatsbyImage.propTypes = GatsbyImageProps

export default GatsbyImage
