import React, { useState } from "react"

import { useCheckout } from "../../hooks/useCheckout"

export const withCartDiscountCode = Component => ({ name = "CartDiscountCode", className, layout }) => {
  const { applyDiscountCode } = useCheckout()
  const [discountCode, setDiscountCode] = useState("")

  const handleApply = () => {
    applyDiscountCode(discountCode)
  }

  const handleChange = ({ target: { value } }) => {
    setDiscountCode(value)
  }

  Component.displayName = name
  return <Component discountCode={discountCode} handleApply={handleApply} handleChange={handleChange} className={className} layout={layout} />
}
