import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { HiddenSpan } from "../Styled/Accessibility"
import Icon from "../Icon"

const StyledBrandLink = styled(Link)`
  ${tw`block w-1/9 flex justify-center p-0-4 md:p-0-8`}
`
const StyledLogo = styled(Icon)`
  ${tw`text-dark`}
`

const HeaderBrand = ({ title }) => (
  <StyledBrandLink to={`/`}>
    <StyledLogo name={`logo`} size={`large`} />
    <HiddenSpan>{title}</HiddenSpan>
  </StyledBrandLink>
)

HeaderBrand.propTypes = {
  title: PropTypes.string,
}

HeaderBrand.defaultProps = {
  title: "",
}

export default HeaderBrand
