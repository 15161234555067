import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"
import { Animate } from "react-show"

import Icon from "./Icon"

const styles = {
  border: {
    header: tw`border-t border-beige px-2-4`,
    footer: tw`border-t border-grey px-2-4`,
    account: tw`border-t border-grey px-2-4`,
    filter: tw`border-b border-grey`,
    product: tw``,
    blog: tw``,
  },
  layout: {
    header: tw`h-8 justify-between`,
    footer: tw`h-8 justify-between`,
    account: tw`h-8 justify-between`,
    filter: tw`h-6-7 justify-between items-center`,
    product: tw`h-4-8 justify-start`,
    blog: tw`h-4-8 justify-start`,
  },
  size: {
    header: tw`text-28 leading-1.29 font-medium font-display`,
    footer: tw`text-16 leading-1.81 font-medium`,
    account: tw`text-16`,
    filter: tw`text-16`,
    product: tw`text-16 leading-3 font-medium w-full text-left`,
    blog: tw`text-16 leading-3 font-medium w-full`,
  },
}

const Wrapper = styled.div`
  ${tw`w-full text-dark`}
  ${({ size }) => (size ? styles.border[size] : null)}
`

const TitleWrapper = styled.button`
  ${tw`w-full flex items-center focus:outline-none`}
  ${({ size }) => (size ? styles.layout[size] : null)}
`

const Title = styled.h3`
  ${({ size }) => (size ? styles.size[size] : null)}
`

const List = styled.ul`
  ${({ size }) => (size === `account` ? tw`pb-4` : null)}
`

export const AccordionAnimation = ({ active, children }) => (
  <Animate
    show={active}
    transitionOnMount
    stayMounted
    style={{
      height: "auto",
      display: "block",
      overflow: "hidden",
    }}
    start={{
      height: 0,
      display: "block",
    }}
  >
    {children}
  </Animate>
)

const Accordion = ({ title, children, size, link, icon }) => {
  const [active, setActive] = useState(false)
  return (
    <Wrapper size={size}>
      <TitleWrapper size={size} onClick={() => setActive(!active)}>
        {size === `product` || size === `blog` ? (
          <Title size={size}>{`${active ? `${title} -` : `${title} +`}`}</Title>
        ) : icon ? (
          <>
            <Title size={size}>{title}</Title>
            {!active ? <Icon name={`plus`} size={`xxs`} /> : <Icon name={`minus`} size={`xxs`} />}
          </>
        ) : (
          <Title as={Link} size={size} to={link}>
            {title}
          </Title>
        )}
      </TitleWrapper>
      <AccordionAnimation active={active}>
        <List size={size}>{children}</List>
      </AccordionAnimation>
    </Wrapper>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.keys(styles.size)),
  link: PropTypes.string,
  icon: PropTypes.bool,
}

export default Accordion
