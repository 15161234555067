import { useState, useCallback } from "react"

import { useCore } from "./useCore"
import { useLocation } from "./useLocation"
import { useApp } from "./useApp"

export const useFunctions = () => {
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { shopify } = useLocation()
  const { customer, setCustomer } = useApp()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const request = useCallback(
    async (endpoint, data) => {
      setLoading(true)
      setErrors([])

      return fetch(endpoint, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Shop-Domain": shopify?.shopName,
        },
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(result => {
          setLoading(false)
          setErrors(result?.userErrors)
          return result
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          setErrors([error])
          return error
        })
    },
    [setLoading, setErrors, fetch, shopify]
  )

  const customerSubscribe = useCallback(
    async (email, tags) => {
      const { customer, userErrors } = await request("/api/customer/subscribe", { email, tags })
      return { customer, userErrors }
    },
    [request]
  )

  const customerTags = useCallback(
    async tags => {
      const id = decodeBase64(customer?.id)
      const { userErrors } = await request("/api/customer/tags", { id, tags })
      if (!userErrors?.length) setCustomer(prevState => ({ ...prevState, tags }))
    },
    [customer, decodeBase64, request, setCustomer]
  )

  const formSubmission = useCallback(
    async (form, data, ipData, userAgent) => {
      const id: string = form?.id
      const to: string = form?.types[data?.type?.toLowerCase()]?.recipients
      const subject: string = form?.subject || `Form submission from ${form?.title}`
      const { status, body } = await request("/api/form/submission", { id, to, subject, data, ipData, userAgent })
      return { status, body }
    },
    [request]
  )

  return { customerSubscribe, customerTags, formSubmission, loading, errors }
}
