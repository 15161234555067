import React, { useMemo } from "react"

import { useSettings } from "../../hooks/useSettings"

export const withFooterSocialLinks = Component =>
  React.memo(({ name = "FooterSocialLinks" }) => {
    const {
      social: { channels: items },
    } = useSettings()
    Component.displayName = name
    return useMemo(() => <Component items={items} />, [items])
  })
